import { useFilterOptions } from '@copilot-dash/store'
import { Spinner } from '@fluentui/react-components'
import { memo, useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const LanguageFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('Prompt Details', 'Prompt Language')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])

  return filterOptionsSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <CopilotDashMultiSelectDropdown
      comboId="LanguageFilter"
      filterType={getTagDisplayName('promptLanguages')}
      optionsList={filterOptions}
      isGroup={false}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
      placeholder="Search Prompt languages"
      showInput={true}
    />
  )
})

LanguageFilter.displayName = 'LanguageFilter'
