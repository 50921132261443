import { Text, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { Row, Spacer, Column } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { IActivityHistory, TicketIssueData, ActivityHistoryRootCauseContext } from '@copilot-dash/domain'
import { useActivityHistoryPanelStore } from '../../store/store'
interface IProps {
  readonly activity?: IActivityHistory
}

export const TeamFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()
  const isChange = useMemo(() => {
    return (
      !!(activity?.fieldDiffs && activity?.fieldDiffs['teamArea'] && activity?.fieldDiffs['teamArea'].newValue) ||
      !!(activity?.fieldDiffs && activity?.fieldDiffs['teamId'] && activity?.fieldDiffs['teamId'].newValue)
    )
  }, [activity])

  const renderValue = (
    value: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
  ) => {
    if (typeof value === 'string') {
      return value
    }
    return null
  }

  const teamsSnapshot = useActivityHistoryPanelStore((state) => state.teamsSnapshot)

  const newValue = useMemo(() => {
    if (activity?.fieldDiffs?.['teamId']?.newValue && teamsSnapshot.data) {
      const team = teamsSnapshot.data.find((team) => team.id === activity?.fieldDiffs?.['teamId']?.newValue?.toString())
      return team?.name
    }
    return renderValue(activity?.fieldDiffs?.['teamArea']?.newValue)
  }, [activity, teamsSnapshot])

  const oldValue = useMemo(() => {
    if (activity?.fieldDiffs?.['teamId']?.oldValue && teamsSnapshot.data) {
      const team = teamsSnapshot.data.find((team) => team.id === activity?.fieldDiffs?.['teamId']?.oldValue?.toString())
      return team?.name
    }
    return renderValue(activity?.fieldDiffs?.['teamArea']?.oldValue)
  }, [activity, teamsSnapshot])

  if (!isChange) return null

  return (
    <Row>
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Team:</Text>
      </Column>
      {newValue && (
        <Text className={styles.tag} title={newValue}>
          {newValue}
        </Text>
      )}
      <Spacer width="4px" />
      {oldValue && (
        <Text className={mergeClasses(styles.tag, styles.deleted)} title={oldValue}>
          {oldValue}
        </Text>
      )}
    </Row>
  )
}
