import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketMessageContent } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOcvTicket } from '../actions-raw-ticket/getRawOcvTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketLastTurnMessageId } from '../actions-ticket-chat/getTicketLastTurnMessageId'
import { getTicketTurnConversation } from '../actions-ticket-chat/getTicketTurnConversation'

export function getTicketResponse(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketMessageContent> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.response
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.response = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketMessageContent> {
    if (context.enableV2Endpoint) {
      return doFetchFromV2()
    }

    try {
      return await fetchFromConversation()
    } catch (odsError) {
      try {
        return await fetchFromOCV()
      } catch (ocvError) {
        throw odsError
      }
    }
  }

  async function doFetchFromV2(): Promise<ITicketMessageContent> {
    await assertTicketHasUserConsent(context, ticketId)

    // 1. Get value
    const raw = await getRawV2Ticket(context, ticketId).promise
    const response = raw.diagnosticContext?.chat?.[0]?.response
    if (response) {
      return {
        text: response.text,
        textInEnglish: response.textInEnglish,
        markdown: response.text,
        markdownInEnglish: response.textInEnglish,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoResponse', { ticketId })
  }

  async function fetchFromConversation(): Promise<ITicketMessageContent> {
    await assertTicketHasUserConsent(context, ticketId)

    const turnId = await getTicketLastTurnMessageId(context, ticketId).promise
    const turn = await getTicketTurnConversation(context, ticketId, turnId).promise
    const content = turn.response?.content
    if (content) {
      return content
    }

    throw TicketError.create('NoResponse', { ticketId })
  }

  async function fetchFromOCV(): Promise<ITicketMessageContent> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise
    const responseMessage = ocvTicket.AiContext?.ResponseMessage
    const responseMessageInEnglish = ocvTicket.AiContext?.ResponseMessageInEnglish
    if (responseMessage) {
      return {
        text: responseMessage,
        textInEnglish: responseMessageInEnglish,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoResponse', { ticketId })
  }
}
