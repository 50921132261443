import { useEffect, useMemo, useState } from 'react'
import { MarkdownView } from '../components/Markdown/MarkdownView'
import DOMPurify from 'dompurify'
import { updateImageSrc } from '../components/DiscussionPanel/children/CommentUtil'
import { Spinner } from '@fluentui/react-components'
import { ImagePreviewModal } from '../components/Image/ImagePreviewModal'
import { Column } from '../components/Layout'

interface RenderContentProps {
  rawComment: string
  renderCommentValue: string | undefined
  isLexicalData?: boolean
  markdownStyle?: string
  deletedStyle?: string
  isOldComment?: boolean
}

export const RenderContent: React.FC<RenderContentProps> = ({
  rawComment,
  renderCommentValue,
  isLexicalData,
  markdownStyle,
  deletedStyle,
  isOldComment,
}) => {
  const [updatedHtml, setUpdatedHtml] = useState<string | null>(null)
  const [modalSrc, setModalSrc] = useState<string | null>(null)

  const handleImageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement
    if (target.tagName === 'IMG') {
      const imageUrl = (event.target as HTMLImageElement).src
      setModalSrc(imageUrl)
    }
  }
  const sanitizedComment = useMemo(() => {
    return DOMPurify.sanitize(rawComment, { ADD_ATTR: ['target'] })
  }, [rawComment])

  useEffect(() => {
    if (isLexicalData) {
      updateImageSrc(sanitizedComment).then(setUpdatedHtml)
    }
  }, [isLexicalData, sanitizedComment])

  if (sanitizedComment === '') {
    return <div className={isOldComment ? deletedStyle : undefined}>{renderCommentValue}</div>
  }
  if (updatedHtml === null && isLexicalData) {
    return <Spinner style={{ height: '300px' }} />
  }

  return isLexicalData ? (
    <Column>
      {modalSrc && <ImagePreviewModal imageUrl={modalSrc} onClose={() => setModalSrc(null)} />}
      {updatedHtml && (
        <div className={deletedStyle} dangerouslySetInnerHTML={{ __html: updatedHtml }} onClick={handleImageClick} />
      )}
    </Column>
  ) : (
    <MarkdownView className={markdownStyle}>{renderCommentValue}</MarkdownView>
  )
}
