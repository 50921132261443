import { z } from 'zod'

export interface ApiCreateRootCauseRequest {
  readonly Title: string
  readonly Description: string
  readonly Priority: number
  readonly VsoAccount: string
  readonly TeamId: number
  readonly ProductId?: number
  readonly Owner?: string
  readonly OriginalCommittedDate?: string
  readonly RootCauseStatus?: string
}

export interface ApiCreateRootCauseResponse {
  readonly workItemId: string
  readonly errorMessage: string
}

export const ApiCreateRootCauseResponseSchema = z.object({
  workItemId: z.string(),
  errorMessage: z.string(),
})
