import { Button, Text } from '@fluentui/react-components'
import { Column, Row } from '../../../components/Layout'
import { useStyles } from './TeamDRI.style'
import { AddRegular, EditRegular } from '@fluentui/react-icons'
import { TeamDRIDialog } from './TeamDRIDialog'
import { useEffect, useState } from 'react'
import { useTeamViewStore } from '../store'
import { UserProfile } from '../../../components/User/UserProfile'
import { UserPhoto } from '../../../components/User/UserPhoto'
import { FloatingTooltip, FloatingTooltipContent, FloatingTooltipTrigger } from '../../../components/FloatingTooltip'
import { UserDisplayName } from '../../../components/User/UserDisplayName'

export const TeamDRI = () => {
  const styles = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)
  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const [teamDRIList, setTeamDRIList] = useState<string[]>([])
  const [selectedTeamDRIList, setSelectedTeamDRIList] = useState<string[]>([])

  useEffect(() => {
    teamId &&
      application.store.actions.getTeamDRIListByTeamId(teamId).then((res) => {
        setTeamDRIList(res)
        setSelectedTeamDRIList(res)
      })
  }, [teamId])

  const tooltipContent = (userId: string) => {
    return (
      <Row className={styles.tooltipContent}>
        <UserPhoto userId={userId} className={styles.customUserPhoto} />
        <Column className={styles.userInfo}>
          <UserDisplayName
            userId={userId}
            className={styles.customUserName}
            isEnableToolTip={false}
            fallback={userId}
          />
          <Text>{userId}</Text>
        </Column>
      </Row>
    )
  }

  return (
    <Row space="between" className={styles.container}>
      <Text className={styles.title}>Team DRI</Text>
      {selectedTeamDRIList.length > 0 ? (
        <Row className={styles.container}>
          {selectedTeamDRIList.length === 1 ? (
            <Row className={styles.singleUser}>
              <UserProfile
                userId={selectedTeamDRIList[0]!}
                fallback={selectedTeamDRIList[0]}
                customUserPhotoClassName={styles.customUserPhoto}
                customUserDisplayNameClassName={styles.customUserName}
              />
            </Row>
          ) : (
            <Row>
              {selectedTeamDRIList.map((item) => (
                <FloatingTooltip key={item}>
                  <FloatingTooltipTrigger>
                    <div>
                      <UserPhoto userId={item} className={styles.customUserPhoto} />
                    </div>
                  </FloatingTooltipTrigger>
                  <FloatingTooltipContent>{tooltipContent(item)}</FloatingTooltipContent>
                </FloatingTooltip>
              ))}
            </Row>
          )}
          <EditRegular className={styles.edit} onClick={() => setDialogOpen(true)} />
        </Row>
      ) : (
        <Button
          appearance="transparent"
          className={styles.addNew}
          icon={<AddRegular />}
          onClick={() => setDialogOpen(true)}
        >
          {' '}
          Add new
        </Button>
      )}
      <TeamDRIDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
        teamDRIList={teamDRIList}
        selectedTeamDRIList={selectedTeamDRIList}
        setSelectedTeamDRIList={setSelectedTeamDRIList}
      />
    </Row>
  )
}
