import { z } from 'zod'

type Email = string
export interface ApiV2FeatureTeamsResponse {
  readonly teamId: string
  readonly teamName: string
  readonly adoConfiguration?: {
    readonly adoAccount: string
    readonly adoAreaPath: string
  }
  readonly isAssignable: boolean
  readonly isDeleted: boolean
  readonly driList: Array<Email>
}

export const apiV2FeatureTeamsResponseSchema = z.object({
  teamId: z.string(),
  teamName: z.string(),
  adoConfiguration: z
    .object({
      adoAccount: z.string(),
      adoAreaPath: z.string(),
    })
    .optional(),
  isAssignable: z.boolean(),
  isDeleted: z.boolean(),
  driList: z.array(z.string()),
})
