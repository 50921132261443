import { Skeleton, SkeletonItem, Text, makeStyles } from '@fluentui/react-components'
import { ErrorView } from '../../../../components/Error'
import { Row, Spacer } from '../../../../components/Layout'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenVerbatimContent } from './TicketScreenVerbatimContent'
import { TicketScreenVerbatimScreenshots } from './TicketScreenVerbatimScreenshots'

export function TicketScreenVerbatim() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const verbatimSnapshot = app.store.use.getTicketVerbatim(ticketId)

  return (
    <Row className={styles.root} hAlign="stretch">
      <Scrollbar className={styles.scrollbar}>
        {(() => {
          switch (verbatimSnapshot.status) {
            case 'waiting':
              return (
                <Skeleton appearance="translucent">
                  <SkeletonItem />
                  <Spacer height={8} />
                  <SkeletonItem />
                </Skeleton>
              )
            case 'done':
              return (
                <Text>
                  <b>Verbatim: </b>
                  <TicketScreenVerbatimContent content={verbatimSnapshot.data} />
                  <span>{` `}</span>
                  <TicketScreenVerbatimScreenshots />
                </Text>
              )
            case 'error':
              return <ErrorView type="inline" error={verbatimSnapshot.error} />
          }
        })()}
      </Scrollbar>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    maxHeight: '64px',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  scrollbar: {
    width: '100%',
    position: 'relative',
  },
})
