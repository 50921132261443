import { OPGProductEndpoints, ProductIds, ScenarioNames } from '@copilot-dash/domain'
import { TicketScreenMainTab } from '../store/IState'

export async function getSupportedMainTabs(ticketId: string): Promise<TicketScreenMainTab[]> {
  const ticketEnvironment = await app.store.actions.getTicketEnvironment(ticketId)
  const messageIds = await app.store.actions.getTicketMessageIds(ticketId)
  if (messageIds.length === 0) {
    return []
  }

  return Object.values(TicketScreenMainTab).filter((tab) => {
    switch (tab) {
      case TicketScreenMainTab.SymptomReport:
        return supportSymptomReport()
      case TicketScreenMainTab.UserpSearchData:
        return ticketEnvironment.productId === ProductIds.USERP
      case TicketScreenMainTab.ConversationData:
      case TicketScreenMainTab.Telemetry:
      case TicketScreenMainTab.KustoLog:
        return true
    }
  })

  function supportSymptomReport(): boolean {
    const isM365Chat = ticketEnvironment.productId === ProductIds.M365Chat
    const isAIF = ticketEnvironment.scenarioName?.toLowerCase() === ScenarioNames.AIF.toLowerCase()
    const isOPG = OPGProductEndpoints.some(
      (client) => client?.toLowerCase() === ticketEnvironment.clientName?.toLowerCase(),
    )

    return isM365Chat && !isAIF && !isOPG
  }
}
