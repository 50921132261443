import { useMemo } from 'react'

export function useTranslation<T>(value: T, valueInEnglish: T | undefined): { isTranslated: boolean; value: T }

export function useTranslation<T>(
  value: T | undefined,
  valueInEnglish: T | undefined,
): { isTranslated: boolean; value: T | undefined }

export function useTranslation<T>(value: T | undefined, valueInEnglish: T | undefined) {
  const translation = app.settings.translateContentToEnglish.use()

  return useMemo(() => {
    if (translation && value && valueInEnglish && value !== valueInEnglish) {
      return {
        isTranslated: true,
        value: valueInEnglish,
      }
    }

    return {
      isTranslated: false,
      value,
    }
  }, [value, valueInEnglish, translation])
}
