import { IUpdateTicketStatusInfoData } from '@copilot-dash/domain'

export interface IState {
  readonly ticketId: string

  readonly supportedOutlineTabs: TicketScreenOutlineTab[]
  readonly supportedMainTabs: TicketScreenMainTab[]
  readonly supportedKustoTabs: TicketScreenKustoTab[]

  readonly selectedTurnId: string | undefined
  readonly selectedScopeId: string | undefined

  readonly selectedOutlineTab: TicketScreenOutlineTab
  readonly selectedMainTab: TicketScreenMainTab | undefined
  readonly selectedKustoTab: TicketScreenKustoTab | undefined

  readonly isOpenDiscussionPanel: boolean
  readonly isOpenActivityDiscussionPanel: boolean

  readonly activeDrawer: TicketScreenDrawerType | undefined

  afterSavedCallback?: (savedInfo: IUpdateTicketStatusInfoData) => void
}

export enum TicketScreenOutlineTab {
  Conversation = 'Conversation',
  CallFlow = 'CallFlow',
}

export enum TicketScreenMainTab {
  SymptomReport = 'SymptomReport',
  ConversationData = 'ConversationData',
  Telemetry = 'Telemetry',
  UserpSearchData = 'UserpSearchData',
  KustoLog = 'KustoLog',
}

export enum TicketScreenKustoTab {
  SssLogs = 'SssLogs',
  StateAndDuration = 'StateAndDuration',
  TraceLog = 'TraceLog',
  LlmLog = 'LlmLog',
  BizChat3SLatency = 'BizChat3SLatency',
  BizChatPerf = 'BizChatPerf',
  ExtensibilityLog = 'ExtensibilityLog',
}

export const TicketScreenMainTabNames: {
  readonly [key in TicketScreenMainTab]: { readonly name: string }
} = {
  [TicketScreenMainTab.SymptomReport]: {
    name: 'Symptom Report',
  },
  [TicketScreenMainTab.ConversationData]: {
    name: 'Conversation Messages',
  },
  [TicketScreenMainTab.Telemetry]: {
    name: 'Sydney Telemetry',
  },
  [TicketScreenMainTab.UserpSearchData]: {
    name: 'USERP Search Logs',
  },
  [TicketScreenMainTab.KustoLog]: {
    name: 'More Logs',
  },
}

export const TicketScreenKustoTabDefinitions = {
  [TicketScreenKustoTab.SssLogs]: {
    name: '3S Logs',
  },
  [TicketScreenKustoTab.StateAndDuration]: {
    name: 'State & Duration',
  },
  [TicketScreenKustoTab.TraceLog]: {
    name: 'Trace Log',
  },
  [TicketScreenKustoTab.LlmLog]: {
    name: 'LLM Log',
  },
  [TicketScreenKustoTab.BizChat3SLatency]: {
    name: 'BizChat 3S Latency',
  },
  [TicketScreenKustoTab.BizChatPerf]: {
    name: 'BizChat Performance',
  },
  [TicketScreenKustoTab.ExtensibilityLog]: {
    name: 'Extensibility Logs',
  },
} as const satisfies Record<TicketScreenKustoTab, { readonly name: string }>

export enum TicketScreenDrawerType {
  TicketContext = 'TicketContext',
  DiscussionPanel = 'DiscussionPanel',
  ActivityHistory = 'ActivityHistory',
}
