import { IRootCauseListByTeam } from '@copilot-dash/domain'
import { useCallback, useEffect, useState } from 'react'
import { useTeamViewStore } from '../../store'
import { usePromise } from '@copilot-dash/core'
import { SUPPORTED_TAGS } from '../../../../components/TicketsFilterPanel/config/SupportedTags'

export function useRootCauseList() {
  const [refetchCounter, setRefetchTrigger] = useState(0)
  const productId = useTeamViewStore((state) => state.computed.productId)
  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const form = useTeamViewStore((state) => state.tickets.filterForm)

  const [rootCauseListByFilterPromise, setRootCauseListByFilterPromise] = useState<Promise<IRootCauseListByTeam>>(
    Promise.resolve<IRootCauseListByTeam>({ rootCauseList: [] }),
  )
  const rootCauseListByFilterSnapshot = usePromise(rootCauseListByFilterPromise)

  const rootCauseSnapshot = application.store.use((state) => state.team.rootCausesMap?.[`${productId}-${teamId}`])

  const searchByTicketCharacterTriggered =
    SUPPORTED_TAGS.filter((tag) => {
      const value = form[tag.key]
      return value && Array.isArray(value) && value.length > 0
    }).length > 0 || !!form.range

  const refreshRootCauseList = useCallback(() => {
    if (!searchByTicketCharacterTriggered) {
      productId && teamId && app.store.actions.clearRootCauseListByTeam(productId, teamId)
      setRefetchTrigger((prev) => prev + 1)
    }
  }, [productId, searchByTicketCharacterTriggered, teamId])

  useEffect(() => {
    if (productId && teamId) {
      application.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
    }
  }, [productId, teamId, refetchCounter])

  useEffect(() => {
    if (teamId && searchByTicketCharacterTriggered) {
      setRootCauseListByFilterPromise(
        application.store.actions.searchRootCauseByTicketCharacter({ ...form, teamId: teamId }),
      )
    }
  }, [teamId, searchByTicketCharacterTriggered, form])

  return [
    searchByTicketCharacterTriggered ? rootCauseListByFilterSnapshot : rootCauseSnapshot,
    refreshRootCauseList,
  ] as const
}
