import { TicketDSATStatusType } from '@copilot-dash/domain'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { OptionSetDSATStatus } from '../../config/OptionSetDSATStatus'
import { z } from 'zod'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: TicketDSATStatusType[] | undefined
  onChangeValue: (value: TicketDSATStatusType[]) => void
  disabled?: boolean
}

export const DSATStatusFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, disabled }) => {
  const optionsList = OptionSetDSATStatus

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-ringFilter'}
      filterType={getTagDisplayName('dSATStatus')}
      isGroup={false}
      disabled={disabled}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const state = z.array(z.nativeEnum(TicketDSATStatusType)).safeParse(item).data
        if (state) {
          onChangeValue(state)
        }
      }}
    />
  )
}
