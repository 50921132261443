import { AsyncLoader, AsyncSnapshot } from '@copilot-dash/core'
import { IActivityHistory, ProductIds, ITeamData } from '@copilot-dash/domain'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

interface IActivityHistoryPanelStore {
  ticketId: string
  setTicketId: (ticketId: string) => void
  selectedActivity?: IActivityHistory
  setSelectedActivity: (activity: IActivityHistory) => void
  activityHistory: AsyncSnapshot<IActivityHistory[]>
  getActivityHistory: (ticketId: string) => Promise<void>
  teamsSnapshot: AsyncSnapshot<ITeamData[]>
  getTeamsForTicket: (ticketId: string) => Promise<void>
}

export const useActivityHistoryPanelStore = create<
  IActivityHistoryPanelStore,
  [['zustand/subscribeWithSelector', never], ['zustand/immer', never]]
>(
  subscribeWithSelector(
    immer((set, get) => {
      const asyncLoader = new AsyncLoader<IActivityHistory[]>()
      const teamsLoader = new AsyncLoader<ITeamData[]>()

      return {
        ticketId: '',
        setTicketId: (ticketId) => {
          set((state) => {
            state.ticketId = ticketId
          })
        },
        selectedActivity: undefined,
        setSelectedActivity: (activity: IActivityHistory) => {
          set((state) => {
            if (state.selectedActivity?.activityId === activity?.activityId) {
              state.selectedActivity = undefined
            } else {
              state.selectedActivity = activity
            }
          })
        },
        activityHistory: { status: 'none' },

        getActivityHistory: async (ticketId: string) => {
          asyncLoader.submit({
            promise: app.store.actions.getTicketActivityHistory(ticketId, true),
            onChanged: (snapshot) => {
              set((state) => {
                state.activityHistory = snapshot
                state.selectedActivity = snapshot.data?.[0]
              })
            },
          })
        },

        teamsSnapshot: { status: 'none' },
        getTeamsForTicket: async (ticketId: string) => {
          teamsLoader.submit({
            promise: (async () => {
              // Fetch the ticket data to get the productId
              const ticketData = await app.store.actions.getTicket(ticketId)
              const productId = ticketData?.productId

              // Fetch the teams using the productId
              return app.store.actions.getTeams(productId ?? ProductIds.M365Chat)
            })(),
            onChanged: (snapshot) => {
              set((state) => {
                state.teamsSnapshot = snapshot
              })
            },
          })
        },
      }
    }),
  ),
)
