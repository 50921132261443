import { IFilterOption } from '../Filter/IFilterOption.types'
import { ISearchTicketArgs } from '@copilot-dash/store'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { getTagDisplayName } from '../TicketsFilterPanel/config/SupportedTags'

export const CustomizedFilterField = (
  form: ISearchTicketArgs,
  supportedTicketFilterItems: SearchFilterFieldNames[],
): IFilterOption[] => [
  {
    key: 'isTopi18N',
    text: getTagDisplayName('isTopi18N'),
    disabled: !!form.isTopi18N?.length,
    hidden: !supportedTicketFilterItems.includes('isTopi18N'),
  },
  {
    key: 'isSTCAChina',
    text: getTagDisplayName('isSTCAChina'),
    disabled: !!form.isSTCAChina?.length,
    hidden: !supportedTicketFilterItems.includes('isSTCAChina'),
  },
  {
    key: 'hasGPTExtension',
    text: getTagDisplayName('hasGPTExtension'),
    disabled: !!form.hasGPTExtension?.length,
    hidden: !supportedTicketFilterItems.includes('hasGPTExtension'),
  },
  {
    key: 'hasMessageExtension',
    text: getTagDisplayName('hasMessageExtension'),
    disabled: !!form.hasMessageExtension?.length,
    hidden: !supportedTicketFilterItems.includes('hasMessageExtension'),
  },
  {
    key: 'hasConnector',
    text: getTagDisplayName('hasConnector'),
    disabled: !!form.hasConnector?.length,
    hidden: !supportedTicketFilterItems.includes('hasConnector'),
  },
  {
    key: 'isGCIntent',
    text: getTagDisplayName('isGCIntent'),
    disabled: !!form.isGCIntent?.length,
    hidden: !supportedTicketFilterItems.includes('isGCIntent'),
  },
  {
    key: 'hasConnectorResult',
    text: getTagDisplayName('hasConnectorResult'),
    disabled: !!form.hasConnectorResult?.length,
    hidden: !supportedTicketFilterItems.includes('hasConnectorResult'),
  },
  {
    key: 'semanticSearchType',
    text: getTagDisplayName('semanticSearchType'),
    disabled: !!form.semanticSearchType?.length,
    hidden: !supportedTicketFilterItems.includes('semanticSearchType'),
  },
  {
    key: 'experienceType',
    text: getTagDisplayName('experienceType'),
    disabled: !!form.experienceType?.length,
    hidden: !supportedTicketFilterItems.includes('experienceType'),
  },
  {
    key: 'errorCode',
    text: getTagDisplayName('errorCode'),
    disabled: !!form.errorCode?.length,
    hidden: !supportedTicketFilterItems.includes('errorCode'),
  },
]
