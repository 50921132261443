import { ColumnIdNameMapping, ColumnKey, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { useTranslation } from '../Translation/useTranslation'
import { CopilotDashCustomHeaderProps, CustomHeader } from './CustomHeaderComponent'
import { RenderLongText } from './RenderLongText'
import { ResponsiveUtteranceColumnComponent } from './ResponsiveUtteranceColumnComponent'

export const createColumnConfigUserUtterance = (
  searchTextPrefix: SearchTextPrefixType,
  keyWords?: string,
): ColDef<INewTicketData> => {
  return {
    headerName: ColumnIdNameMapping[ColumnKey.Utterance],
    field: String(ColumnKey.Utterance) as NestedFieldPaths<INewTicketData>,
    cellRenderer: (props: CustomCellRendererProps) => {
      return <Cell data={props.data} searchTextPrefix={searchTextPrefix} keyWords={keyWords} />
    },
    headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
    headerComponentParams: {
      customHeaderContent: (
        <TableCellLayoutWithStyles truncate>{ColumnIdNameMapping[ColumnKey.Utterance]}</TableCellLayoutWithStyles>
      ),
    },
    flex: 2,
    minWidth: 190,
    sortable: false,
    resizable: true,
  }
}

interface ICellProps {
  readonly data: INewTicketData
  readonly searchTextPrefix: SearchTextPrefixType
  readonly keyWords?: string
}

function Cell({ data, searchTextPrefix, keyWords }: ICellProps) {
  const originalTranslation = useTranslation(data.utterance, data.utteranceInEnglish)
  const highlightTranslation = useTranslation(data.utteranceWithHighlight, data.utteranceInEnglishWithHighlight)

  if (originalTranslation.value) {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderLongText
          originText={originalTranslation.value}
          highlightText={highlightTranslation.value}
          isTranslated={originalTranslation.isTranslated || highlightTranslation.isTranslated}
        />
      </TableCellLayoutWithStyles>
    )
  }

  return (
    <TableCellLayoutWithStyles truncate>
      {data.ticketId && (
        <ResponsiveUtteranceColumnComponent item={data} searchTextPrefix={searchTextPrefix} keyWords={keyWords} />
      )}
    </TableCellLayoutWithStyles>
  )
}
