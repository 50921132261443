import { AsyncSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { useMemo } from 'react'
import { useDashStore } from './DashStoreProvider'

export interface IFilterOption {
  key: string
  text: string
  menu?: string
  disabled?: boolean
}

export function useFilterOptions(
  filterCategory: string,
  filterFiledName: string,
  filterTagPrefix?: string,
): AsyncSnapshot<IFilterOption[]> {
  const store = useDashStore()
  const snapshot = store.use.getFilterTags()

  return useMemo(() => {
    return PromiseSnapshots.map(snapshot, (tag) => {
      const optionList: IFilterOption[] = []
      // If a filterTagPrefix is provided, filter tags that start with the prefix
      if (filterTagPrefix) {
        const filterTag = tag.filter((t) => t.tagDisplayValue?.startsWith(filterTagPrefix))
        filterTag.forEach((findTag) => {
          if (findTag.tagDisplayValue && findTag.filterValue) {
            const key = findTag.tagDisplayValue.toString()
            const text = findTag.filterValue.toString()
            optionList.push({
              key: key,
              text: text,
            })
          }
        })
        return optionList
      }

      // If filterCategory and filterFiledName are empty, return all tags
      if (!filterCategory && !filterFiledName) {
        tag.forEach((findTag) => {
          if (findTag.tagDisplayValue && findTag.filterValue) {
            const key = findTag.tagDisplayValue.toString()
            const text = findTag.filterValue.toString()
            optionList.push({
              key: key,
              text: text,
            })
          }
        })
        return optionList
      }

      // Filter tags based on filterCategory, and filterFiledName value
      const filterTag = tag.filter(
        (t) =>
          t.isDisplay &&
          t.filterCategory?.trim() === filterCategory.trim() &&
          t.filterFieldName?.trim() === filterFiledName.trim() &&
          t.filterValue !== '',
      )

      filterTag.forEach((findTag) => {
        if (findTag.tagDisplayValue && findTag.filterValue) {
          const key = findTag.tagDisplayValue.toString()
          const text = findTag.filterValue.toString()

          optionList.push({
            key: key,
            text: text,
          })
        }
      })
      return optionList
    })
  }, [filterCategory, filterFiledName, filterTagPrefix, snapshot])
}
