import { useFilterOptions } from '@copilot-dash/store'
import { Spinner } from '@fluentui/react-components'
import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (InvocationTypes: string[]) => void
}

export const ExperienceTypeFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('', '', 'ExperienceType:')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data.sort((a, b) => a.text.localeCompare(b.text))
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])

  return filterOptionsSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <CopilotDashMultiSelectDropdown
      comboId="ExperienceTypeFilter"
      filterType={getTagDisplayName('experienceType')}
      optionsList={filterOptions}
      isGroup={false}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
}
