import { Column, Row, Spacer } from '../../../Layout'
import { QueryRowInPanel } from '../queries/QueryRowInPanel'
import { useTicketFilterStore } from '../../store/store'
import { FC, memo } from 'react'
import { makeStyles, tokens, Spinner } from '@fluentui/react-components'
import { ErrorView } from '../../../Error/ErrorView'
import { TeamId } from '@copilot-dash/domain'
interface IProps {
  teamId: TeamId
}

export const TeamQueries: FC<IProps> = memo(({ teamId }: IProps) => {
  const styles = useStyles()
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)

  const queriesSnapshot = application.store.use.getTeamQueries(teamId, selectedProductId)

  return (
    <Column fill>
      {queriesSnapshot.status === 'waiting' ? (
        <Row fill vAlign="center" hAlign="center">
          <div className={styles.loading}>
            <Spinner labelPosition="below" label="Loading..." />
          </div>
        </Row>
      ) : (
        <Column>
          <Spacer height="12px" />
          {queriesSnapshot.data?.length === 0 ? (
            <ErrorView.Custom level="WARNING" message="No queries" />
          ) : (
            queriesSnapshot.data?.map((query) => <QueryRowInPanel key={query.queryId} query={query} />)
          )}
        </Column>
      )}
    </Column>
  )
})

TeamQueries.displayName = 'TeamQueries'

const useStyles = makeStyles({
  title: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightBold,
  },
  loading: {
    width: '100%',
    textAlign: 'center',
  },
})
