import {
  IActivityHistory,
  activityHistoryRootCauseContextSchema,
  ActivityHistoryRootCauseContext,
  ticketIssueDataSchema,
  TicketIssueData,
  RootCauseActionType,
  ITeamData,
} from '@copilot-dash/domain'
import { isNumber } from 'lodash'
import { z } from 'zod'

interface ActivityTicketStatus {
  readonly filedName: string
  readonly isOldValueRequired: boolean
  readonly isNewValueRequired: boolean
}

const ActivityFieldMapping: Record<string, ActivityTicketStatus> = {
  state: { filedName: 'State:', isOldValueRequired: false, isNewValueRequired: false },
  teamArea: { filedName: 'Team:', isOldValueRequired: false, isNewValueRequired: false },
  priority: { filedName: 'Priority:', isOldValueRequired: false, isNewValueRequired: false },
  assignTo: { filedName: 'Assign To:', isOldValueRequired: false, isNewValueRequired: false },
  issueList: { filedName: 'Root Cause:', isOldValueRequired: false, isNewValueRequired: false },
  closedComment: { filedName: 'Closed Comment:', isOldValueRequired: false, isNewValueRequired: true },
  reasoning: { filedName: 'Reasoning:', isOldValueRequired: false, isNewValueRequired: true },
  customTags: { filedName: 'Tags:', isOldValueRequired: false, isNewValueRequired: false },
  teamId: { filedName: 'Team:', isOldValueRequired: false, isNewValueRequired: true },
}

function mapRootCauseActionType(value: string): string {
  switch (value) {
    case RootCauseActionType.UserNoAction:
      return 'no Action'
    case RootCauseActionType.UserRejected:
      return 'Rejected'
    case RootCauseActionType.UserConfirmed:
      return 'Confirmed'
    case RootCauseActionType.DirectLinked:
      return 'Direct Linked'
    case RootCauseActionType.DirectLinkedReviewed:
      return 'Direct Linked Reviewed'
    case RootCauseActionType.DirectLinkedRemoved:
      return 'Direct Linked Removed'
    default:
      return 'Unknown Action'
  }
}
export function getRootCauseDifferentValue(
  firstValue: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
  secondValue: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
): { deleted: TicketIssueData[]; added: TicketIssueData[] } {
  if (!isTicketIssueDataArray(firstValue) && !isTicketIssueDataArray(secondValue)) {
    return { deleted: [], added: [] }
  }
  const firstArray = isTicketIssueDataArray(firstValue) ? firstValue : []
  const secondArray = isTicketIssueDataArray(secondValue) ? secondValue : []

  const filteredFirstValue = filterEmptyIssues(firstArray)
  const filteredSecondValue = filterEmptyIssues(secondArray)

  if (filteredFirstValue.length === 0 && filteredSecondValue.length === 0) {
    return { deleted: [], added: [] }
  }

  const addedItems = filteredFirstValue.filter(
    (newItems) => !filteredSecondValue.some((oldItems) => oldItems.IssueId === newItems.IssueId),
  )

  const deletedItems = filteredSecondValue.filter(
    (oldItems) => !filteredFirstValue.some((newItems) => newItems.IssueId === oldItems.IssueId),
  )

  return { deleted: deletedItems, added: addedItems }
}

export function summarizeActivity(activity: IActivityHistory, teams?: ITeamData[]): string {
  switch (activity.activity) {
    case 'AddComment':
      return 'Added a Comment.'
    case 'UpdateComment':
      return 'Edited a Comment.'
    case 'DeleteComment':
      return 'Deleted a Comment.'
    case 'TicketStatusUpdate':
    case 'WorkItemAutoTriage': {
      const summaries: string[] = []
      if (activity.fieldDiffs) {
        for (const [key, fieldDiff] of Object.entries(activity.fieldDiffs)) {
          const fieldMapping = ActivityFieldMapping[key]
          if (!fieldMapping) continue

          const { filedName } = fieldMapping
          const { oldValue, newValue } = fieldDiff
          if (key === 'issueList') {
            if (
              (isTicketIssueDataArray(fieldDiff.newValue) && filterEmptyIssues(fieldDiff.newValue).length > 0) ||
              (isTicketIssueDataArray(fieldDiff.oldValue) && filterEmptyIssues(fieldDiff.oldValue).length > 0)
            ) {
              const { added, deleted } = getRootCauseDifferentValue(fieldDiff.newValue, fieldDiff.oldValue)
              if (added.length > 0 && deleted.length > 0) {
                summaries.push(`Changed Root Cause`)
              } else if (added.length > 0) {
                summaries.push(`Added Root Cause`)
              } else if (deleted.length > 0) {
                summaries.push(`Deleted Root Cause`)
              }
            }
          } else if (key === 'assignTo') {
            if (newValue) {
              summaries.push(`Assigned ${newValue}`)
            } else if (oldValue) {
              summaries.push(`Unassigned ${oldValue}`)
            }
          } else if (key === 'closedComment' || key === 'reasoning') {
            continue
          } else if (key === 'teamId') {
            if (teams && isNumber(newValue)) {
              const team = teams.find((team) => team.id === newValue.toString())
              if (team) {
                summaries.push(`Changed team to ${team.name}`)
              }
            }
          } else if (oldValue !== undefined && newValue !== undefined) {
            summaries.push(`Changed ${filedName.toLocaleLowerCase().replace(':', '')} to ${newValue}`)
          } else if (oldValue !== undefined) {
            summaries.push(`Removed ${filedName.toLocaleLowerCase().replace(':', '')} from ${oldValue}`)
          } else if (newValue !== undefined) {
            summaries.push(`Updated ${filedName.toLocaleLowerCase().replace(':', '')} to ${newValue}`)
          }
        }

        if (summaries.length > 1) {
          return summaries[0] + ' and made fields changes.'
        }

        return summaries + '.'
      }
      return 'Updated ticket status.'
    }
    case 'SetTicketRootCausingActions': {
      const summary: string[] = []
      if (activity.fieldDiffs) {
        for (const [key, fieldDiff] of Object.entries(activity.fieldDiffs)) {
          if (key === 'rootCauseActiveContext') {
            if (fieldDiff.newValue && isRootCauseContextArray(fieldDiff.newValue)) {
              fieldDiff.newValue.forEach((action) => {
                summary.push(`${mapRootCauseActionType(action.UserAction)} a Root Cause`)
              })
            }
          }
        }

        return summary.join(', and ') + '.'
      }

      return ''
    }
    case 'WorkItemCreated':
      return 'Created work item.'
    case 'FeedbackCreated':
      return 'User Created Feedback.'
    case 'FeedbackCooked':
      return 'Completed Feedback Diagnostic Data Collection.'
    case 'UpdateCustomTags':
      if (activity.fieldDiffs) {
        for (const [key, fieldDiff] of Object.entries(activity.fieldDiffs)) {
          const fieldMapping = ActivityFieldMapping[key]
          if (!fieldMapping) continue
          const { oldValue, newValue } = fieldDiff
          if (key === 'customTags') {
            if (isStringArray(newValue) && newValue.length > 0 && isStringArray(oldValue) && oldValue.length > 0) {
              return 'Updated Tags.'
            } else if (isStringArray(oldValue) && oldValue.length > 0) {
              return `Removed ${oldValue.length} Custom tag${oldValue.length > 1 ? 's.' : ''}`
            } else if (isStringArray(newValue) && newValue.length > 0) {
              return `Added ${newValue.length} Custom tag${newValue.length > 1 ? 's.' : ''}`
            }
          }
        }
      }
      return ''
    default:
      return ''
  }
}

export function isRootCauseContextArray(value: unknown): value is ActivityHistoryRootCauseContext[] {
  return z.array(activityHistoryRootCauseContextSchema).safeParse(value).success
}

function isTicketIssueDataArray(value: unknown): value is TicketIssueData[] {
  return z.array(ticketIssueDataSchema).safeParse(value).success
}

export function isStringArray(value: unknown): value is string[] {
  const stringArraySchema = z.array(z.string())
  return Array.isArray(value) && stringArraySchema.safeParse(value).success
}

function filterEmptyIssues(issueList: TicketIssueData[]): TicketIssueData[] {
  return issueList.filter((issue) => issue.IssueId.trim() !== '')
}
