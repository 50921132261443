import { PromiseSnapshot } from '@copilot-dash/core'
import { ITopIssuesBatchInfoList, ProductIds } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { TeamViewDataConverter } from './converters/TeamViewDataConverter'

export async function getTeamIssueClusteringBatchInfoList(
  context: IDashStoreContext,
  productId: ProductIds,
): Promise<ITopIssuesBatchInfoList> {
  const response = await context.api.logCollector.getIssueClusteringBatchInfoList({ productId: Number(productId) })
  return TeamViewDataConverter.fromApiTopIssuesBatchInfoListResponse(response)
}

export function getOrFetchTeamIssueClusteringBatchInfoList(
  context: IDashStoreContext,
  productId: ProductIds,
): PromiseSnapshot<ITopIssuesBatchInfoList> {
  return context.getOrFetch({
    get: (state) => {
      return state.topIssue.batchList?.[productId]
    },
    set: (state, snapshot) => {
      state.topIssue.batchList ??= {}
      state.topIssue.batchList[productId] = snapshot
    },
    fetch: () => {
      return getTeamIssueClusteringBatchInfoList(context, productId)
    },
  })
}
