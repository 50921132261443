import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { OpenRegular } from '@fluentui/react-icons'
import { Column } from '../../../../../components/Layout'
import { RouteLink } from '../../../../../router'

interface IProps {
  readonly ticketId: string
}

export function UserpSearchData({ ticketId }: IProps) {
  const styles = useStyles()
  const odsLink = `https://ods.office.net/#/saratickets/${ticketId}`

  return (
    <Column vAlign="stretch" hAlign="stretch" fill className={styles.root}>
      <Text>
        <Text>
          The search diagnostic data for USERP will be integrated into Copilot Dash later. In the meantime, you can
          explore diagnostic data on&nbsp;
        </Text>
        <RouteLink path={odsLink} openInNewTab type="link" className={styles.route}>
          ODS&nbsp;
          <OpenRegular className={styles.openIcon} />
        </RouteLink>
        <Text>.</Text>
      </Text>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },
  route: {
    fontWeight: tokens.fontWeightSemibold,
  },
  openIcon: {
    verticalAlign: '-0.15em',
  },
})
