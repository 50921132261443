import { z, ZodType } from 'zod'
import { ApiClient } from '../../client/ApiClient'
import { Caches } from '../../client/ApiClientCaches'
import { DashApiOptions } from '../../DashApiOptions'
import { apiLlmKustoLogItemSchema } from '../LogCollector/types'
import {
  apiConversationGroup1Schema,
  apiConversationGroup2Schema,
  apiConversationGroup3Schema,
  apiConversationGroup4Schema,
  apiOds3sOfflineDataGroup2Schema,
  apiOds3sOfflineDataGroup3Schema,
  apiOds3sOfflineDataGroup4Schema,
  apiOds3sOnlineDataGroup1Schema,
  apiOds3sOnlineDataGroup2Schema,
  apiOds3sOnlineDataGroup3Schema,
  apiOds3sOnlineDataGroup4Schema,
} from '../ODS/types'
import { apiConversationSchema } from '../ODS/types/ApiConversation.schema'
import { apiOds3sOfflineDataGroup1Schema } from '../ODS/types/ApiOds3sOfflineDataGroup1.schema'
import { CopilotDashApiClient } from './CopilotDashApiClient'
import {
  ApiAISearchRequestBody,
  ApiAISearchRequestPrams,
  ApiAISearchResponse,
  apiAISearchResponseSchema,
  apiBizChat3SLatencyLogItemV2Schema,
  apiBizPerfLogItemV2Schema,
  apiExtensibilityLogItemV2Schema,
  apiGwsLogItemV2Schema,
  apiStateAndDurationKustoLogItemV2Schema,
  apiTraceKustoLogItemV2Schema,
  ApiV2SymptomReportResponse,
  apiV2SymptomReportResponseSchema,
  ApiV2Ticket,
  apiV2TicketSchema,
  ApiV2ProductsResponse,
  apiV2ProductsResponseSchema,
  ApiV2FeatureTeamsResponse,
  apiV2FeatureTeamsResponseSchema,
  ApiV2PatchFeatureTeamRequest,
  ApiV2PeopleAssignmentsResponse,
  apiV2PeopleAssignmentsResponseSchema,
  ApiV2RootCausesResponse,
  apiV2RootCausesResponseSchema,
  ApiV2WorkItemsResponse,
  apiV2WorkItemsResponseSchema,
  ApiV2PatchWorkItemsRequest,
  ApiV2PatchWorkItemsResponse,
  apiV2PatchWorkItemsResponseSchema,
  ApiV2RootCauseRecommendationsResponse,
  apiV2RootCauseRecommendationsSchema,
} from './types'
import {
  ApiAttachmentRequest,
  ApiAttachmentResponse,
  ApiAttachmentResponseSchema,
  ApiAttachmentUrlResponse,
  arrayBufferSchema,
} from './types/ApiV2Attachment'
import {
  ApiCommentItemV2,
  apiCommentItemV2Schema,
  apiCommentListResponseV2Schema,
  ApiCommentsResponseV2,
} from './types/ApiV2CommentsResponse'
import { ApiDeleteCommentResponseV2, apiDeleteCommentResponseV2Schema } from './types/ApiV2DeleteCommentResponse'
import { ApiTeamItemV2, apiTeamListResponseV2Schema, ApiV2TeamListResponse } from './types/ApiV2TeamListResponse'
import { ApiUpsertCommentRequestV2 } from './types/ApiV2UpsertCommentRequest'
import { ApiV2ProductListResponse, apiV2ProductListResponseSchema } from './types/ApiV2ProductListResponse'
import { ApiTenantListResponse, apiTenantListResponseSchema } from './types/ApiTenantListResponse'

/**
 * Code:
 * https://dev.azure.com/msasg/Falcon/_git/DSS?path=/services/LogCollector/src/Service/Api/LogCollector/V1/LogCollectorService.bond&_a=contents&version=GBmaster
 *
 * Swagger:
 * https://copilotdashwebservice-service.namcentral240175.copilot-dash-webservice.centralus-test.cosmic-int.office.net/swagger/index.html
 */
export class CopilotDashApi {
  private readonly client: ApiClient

  constructor(options: DashApiOptions) {
    this.client = new CopilotDashApiClient(options)
  }

  getTicket(ticketId: string): Promise<ApiV2Ticket> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}`, {
      schema: apiV2TicketSchema,
    })
  }

  getTicketSymptomReport(ticketId: string): Promise<ApiV2SymptomReportResponse> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}/SymptomReports`, {
      schema: apiV2SymptomReportResponseSchema,
    })
  }

  getAISearchTicket(query: ApiAISearchRequestPrams, data: ApiAISearchRequestBody): Promise<ApiAISearchResponse> {
    return this.client.post(`/api/v2/ticket/search`, {
      params: query,
      data: data,
      schema: apiAISearchResponseSchema,
    })
  }

  getTicketBlob(ticketId: string, blobUrl: string) {
    const get = async <T>(schema: ZodType<T>): Promise<T> => {
      const encodedTicketId = encodeURIComponent(ticketId)
      return this.client.get(`/api/v2/tickets/${encodedTicketId}/blobFile`, {
        params: {
          blobUrl: blobUrl,
        },
        schema: schema,
      })
    }

    return {
      // Conversation
      asConversation: () => get(apiConversationSchema),
      asConversationGroup1: () => get(apiConversationGroup1Schema),
      asConversationGroup2: () => get(apiConversationGroup2Schema),
      asConversationGroup3: () => get(apiConversationGroup3Schema),
      asConversationGroup4: () => get(apiConversationGroup4Schema),

      // Search online
      asSubstrateSearchOnlineGroup1: () => get(apiOds3sOnlineDataGroup1Schema),
      asSubstrateSearchOnlineGroup2: () => get(apiOds3sOnlineDataGroup2Schema),
      asSubstrateSearchOnlineGroup3: () => get(apiOds3sOnlineDataGroup3Schema),
      asSubstrateSearchOnlineGroup4: () => get(apiOds3sOnlineDataGroup4Schema),

      // Search offline
      asSubstrateSearchOfflineGroup1: () => get(apiOds3sOfflineDataGroup1Schema),
      asSubstrateSearchOfflineGroup2: () => get(apiOds3sOfflineDataGroup2Schema),
      asSubstrateSearchOfflineGroup3: () => get(apiOds3sOfflineDataGroup3Schema),
      asSubstrateSearchOfflineGroup4: () => get(apiOds3sOfflineDataGroup4Schema),

      // Kusto
      asSubstrateSearchLog: () => get(z.array(apiGwsLogItemV2Schema)),
      asSubstrateSearchLatencyLog: () => get(z.array(apiBizChat3SLatencyLogItemV2Schema)),
      asPerformanceLog: () => get(z.array(apiBizPerfLogItemV2Schema)),
      asLlmLog: () => get(z.array(apiLlmKustoLogItemSchema)),
      asStateDurationLog: () => get(z.array(apiStateAndDurationKustoLogItemV2Schema)),
      asTraceLog: () => get(z.array(apiTraceKustoLogItemV2Schema)),
      asExtensibility: () => get(z.array(apiExtensibilityLogItemV2Schema)),
    }
  }

  //Due to the current API only support Image/* types. that's why we named it.
  uploadImageV2(data: ApiAttachmentRequest): Promise<ApiAttachmentResponse> {
    const formData = new FormData()
    formData.append('file', data.file)
    return this.client.put(`/api/v2/discussions/attachments`, {
      data: formData,
      schema: ApiAttachmentResponseSchema,
      headers: {
        'Content-Type': data.file.type || 'application/octet-stream',
      },
    })
  }

  getAttachmentV2(rawUrl: string): Promise<ApiAttachmentUrlResponse> {
    return this.client.get('/api/v2/discussions/attachments', {
      params: { url: rawUrl },
      schema: arrayBufferSchema,
      cache: Caches.SHORT,
      responseType: 'arraybuffer',
    })
  }

  createComment(discussionId: string, data: ApiUpsertCommentRequestV2): Promise<ApiCommentItemV2> {
    return this.client.put(`/api/v2/discussions/${encodeURIComponent(discussionId)}/comments`, {
      schema: apiCommentItemV2Schema,
      data: data,
    })
  }

  deleteComment(discussionId: string, commentId: string): Promise<ApiDeleteCommentResponseV2> {
    return this.client.delete(
      `/api/v2/discussions/${encodeURIComponent(discussionId)}/comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiDeleteCommentResponseV2Schema,
      },
    )
  }

  editComment(id: string, commentId: string, data: ApiUpsertCommentRequestV2): Promise<ApiCommentItemV2> {
    return this.client.patch(
      `/api/v2/discussions/${encodeURIComponent(id)}/comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiCommentItemV2Schema,
        data: data,
      },
    )
  }

  getComments(discussionId: string): Promise<ApiCommentsResponseV2> {
    return this.client.get(`/api/v2/discussions/${encodeURIComponent(discussionId)}/comments`, {
      schema: apiCommentListResponseV2Schema,
    })
  }

  // Teams APIs
  getProductList(): Promise<ApiV2ProductListResponse> {
    return this.client.get('/api/v2/configuration/productList', {
      schema: apiV2ProductListResponseSchema,
    })
  }
  getTeamList(productId?: string): Promise<ApiV2TeamListResponse> {
    return this.client.get('/api/v2/teamView/featureTeams', {
      params: {
        productId: productId,
      },
      schema: apiTeamListResponseV2Schema,
    })
  }
  getTeamListFlatten(productId?: string): Promise<Array<Exclude<ApiTeamItemV2, 'subTeamList'>>> {
    return this.getTeamList(productId).then((teams) => {
      const flattenTeams = new Array<Exclude<ApiTeamItemV2, 'subTeamList'>>()
      const flatten = (team: ApiTeamItemV2) => {
        team.isAssignable && flattenTeams.push(team)
        team.subTeamList.forEach(flatten)
      }
      teams.forEach(flatten)
      return flattenTeams
    })
  }
  getProducts(): Promise<ApiV2ProductsResponse> {
    return this.client.get(`/api/v2/configuration/products`, {
      schema: apiV2ProductsResponseSchema,
    })
  }
  getFeatureTeams(teamId: string): Promise<ApiV2FeatureTeamsResponse> {
    return this.client.get(`/api/v2/teamView/featureTeams/${teamId}`, {
      schema: apiV2FeatureTeamsResponseSchema,
    })
  }
  patchFeatureTeams(teamId: string, data: ApiV2PatchFeatureTeamRequest): Promise<ApiV2FeatureTeamsResponse> {
    return this.client.patch(`/api/v2/teamView/featureTeams/${encodeURIComponent(teamId)}`, {
      data: data,
      schema: apiV2FeatureTeamsResponseSchema,
    })
  }
  getPeopleAssignments(teamId: string): Promise<ApiV2PeopleAssignmentsResponse> {
    return this.client.get(`/api/v2/teamView/workItems/peopleAssignments/find`, {
      params: { teamId: teamId },
      schema: apiV2PeopleAssignmentsResponseSchema,
    })
  }
  getRootCauses(teamId: string): Promise<ApiV2RootCausesResponse> {
    return this.client.get(`/api/v2/teamView/rootCauses/find`, {
      params: { teamId: teamId },
      schema: apiV2RootCausesResponseSchema,
    })
  }
  getWorkItems(feedbackId: string, messageId: string): Promise<ApiV2WorkItemsResponse> {
    return this.client.get(`/api/v2/teamView/workItems/find`, {
      params: {
        feedbackId: feedbackId,
        messageId: messageId,
      },
      schema: apiV2WorkItemsResponseSchema,
    })
  }
  patchWorkItems(data: ApiV2PatchWorkItemsRequest): Promise<ApiV2PatchWorkItemsResponse> {
    return this.client.patch(`/api/v2/teamView/workItems`, {
      data: data,
      schema: apiV2PatchWorkItemsResponseSchema,
    })
  }
  getRootCauseRecommendations(feedbackId: string): Promise<ApiV2RootCauseRecommendationsResponse> {
    return this.client.get(`api/v2/teamView/rootCauseRecommendations/${feedbackId}`, {
      schema: apiV2RootCauseRecommendationsSchema,
    })
  }

  getTenantList(keyWord: string, size: number): Promise<ApiTenantListResponse> {
    return this.client.get('api/v2/configuration/tenants', {
      schema: apiTenantListResponseSchema,
      cache: Caches.LONG,
      params: { keyWord: keyWord, size: size },
    })
  }
}
