import { INewTicketData, ITicketMessageContent, SearchTextPrefixType } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { ErrorView } from '../Error'
import { usePrivacyModeClass } from '../Privacy/usePrivacyModeClass'
import { ToolTipText } from '../Text/ToolTipText'
import { useStyles } from './ColumnComponent.styles'
import { useTranslation } from '../Translation/useTranslation'

export function ResponsiveResponseColumnComponent({
  item,
  searchTextPrefix,
  keyWords,
}: {
  item: INewTicketData
  searchTextPrefix: SearchTextPrefixType
  keyWords?: string
}) {
  const privacyModeClass = usePrivacyModeClass()

  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  const styles = useStyles()
  const plainTextKeyWords = useMemo(() => {
    if (!keyWords) return keyWords
    if (searchTextPrefix === SearchTextPrefixType.All || searchTextPrefix === SearchTextPrefixType.Response)
      return keyWords.trim()
    return undefined
  }, [keyWords, searchTextPrefix])

  const tableCellContent = useMemo(() => {
    switch (inView) {
      case true:
        if (item.ticketId) return <ResponseColumnComponent ticketId={item.ticketId} keyWords={plainTextKeyWords} />
        else return <Text className={styles.grayColor}>N/A</Text>
      default:
        return null
    }
  }, [inView, item, plainTextKeyWords, styles.grayColor])

  return (
    <div ref={ref} className={privacyModeClass}>
      {tableCellContent}
    </div>
  )
}

function ResponseColumnComponent({ ticketId, keyWords }: { ticketId: string; keyWords?: string }) {
  const styles = useStyles()
  const snapshot = application.store.use.getTicketResponse(ticketId)

  const renderContent = () => {
    switch (snapshot.status) {
      case 'waiting':
        return (
          <Skeleton appearance="translucent" className={styles.skeleton}>
            <SkeletonItem className={styles.skeletonItem1} />
            <SkeletonItem className={styles.skeletonItem2} />
          </Skeleton>
        )
      case 'done':
        return <Content content={snapshot.data} keywords={keyWords} />
      case 'error':
        return <ErrorView type="inline" error={snapshot.error} />
    }
  }

  return <div>{renderContent()}</div>
}

function Content({ content, keywords }: { content: ITicketMessageContent; keywords?: string }) {
  const translation = useTranslation(
    content.markdown ?? content.text,
    content.markdownInEnglish ?? content.textInEnglish,
  )

  return (
    <ToolTipText
      text={translation.value}
      keywords={keywords}
      isTwoLinesTruncate={true}
      isTranslated={translation.isTranslated}
    />
  )
}
