import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Column, Row, Spacer } from '../../../components/Layout'
import {
  ChevronDownRegular,
  ChevronRightFilled,
  LinkDismissRegular,
  OrganizationRegular,
  PenSparkleRegular,
} from '@fluentui/react-icons'
import {
  Text,
  Button,
  Spinner,
  Tag,
  tokens,
  Link,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
} from '@fluentui/react-components'
import { ColumnKey, columnKeySchema, INewTicketData, ITopIssue } from '@copilot-dash/domain'
import { useTeamViewStore } from '../store'
import { TableLoading } from './TableLoading'
import { ErrorView } from '../../../components/Error'
import { useStyles } from './TopIssueTickets.styles'
import { motion } from 'framer-motion'
import { ALL_TEAMS } from '../../../store/data/team/createTeamStore'
import { RouteLink, SearchRoute } from '../../../router'
import { AGGridTable, IAGGridTableRef } from '../../../components/AGGridTable/AGGridTable'
import { ColDef } from 'ag-grid-community'
import { z } from 'zod'

interface IClusteringTickets {
  batchId: string | null | undefined
  issue: ITopIssue
  columns: ColDef<INewTicketData>[]
  clickedTicketKey?: string
  onRowClick?: (row?: INewTicketData) => void
  onSelectionChange?: (selectedItems: INewTicketData[] | undefined) => void
  tableRef?: (ref: IAGGridTableRef | null) => void
  estimatedHeight?: number
  setColumns: (value: ColumnKey[]) => void
}

function TopIssueTicketsComp({
  batchId,
  issue,
  columns,
  clickedTicketKey,
  onRowClick,
  onSelectionChange,
  tableRef,
  estimatedHeight = 600,
  setColumns,
}: IClusteringTickets) {
  const rowRef = useRef<HTMLDivElement>(null)
  const styles = useStyles()

  const filter = useTeamViewStore((state) => state.tickets.filterForm)

  const teamId = useTeamViewStore.getState().teams.computed.selectedTeamId
  const teamName = useTeamViewStore.getState().teams.lastSelectedTeam
  const isAllTeams = teamName === ALL_TEAMS
  const routeHash = useTeamViewStore.getState().route.args.hash

  const [tableItems, setTableItems] = useState<INewTicketData[]>([])

  const ticketIdsSnapshot = useTeamViewStore((state) => state.tickets.topIssueTicketIds[`${batchId}-${issue.issueId}`])
  const ticketsSnapshot = app.store.use.getTickets(ticketIdsSnapshot?.data?.ticketIds ?? [])

  const handleColumnsChange = useCallback(
    (columns: Array<string>) => {
      const movedColumns = columns
        .map((column) => columnKeySchema.safeParse(column))
        .filter((result) => result.success)
        .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
      setColumns(movedColumns)
    },
    [setColumns],
  )

  useEffect(() => {
    if (ticketIdsSnapshot?.status === 'done' && ticketsSnapshot?.status === 'done') {
      setTableItems(ticketsSnapshot.data ?? [])
    }
  }, [ticketIdsSnapshot?.status, ticketsSnapshot])

  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [expanded, setExpanded] = useState(routeHash === issue.issueId)

  useEffect(() => {
    if (issue.issueId === routeHash && rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [issue.issueId, routeHash])

  useEffect(() => {
    if (batchId && teamId && issue.issueId) {
      useTeamViewStore.getState().tickets.fetchTopIssueTickets(issue.issueId, teamId, batchId, filter)
    }
  }, [batchId, issue.issueId, filter, teamId])

  const handleLoadMore = useCallback(() => {
    const offset = ticketIdsSnapshot?.data?.ticketIds?.length ?? 0
    if (
      typeof teamId === 'number' &&
      batchId &&
      issue.issueId &&
      ticketIdsSnapshot?.status === 'done' &&
      ticketIdsSnapshot.data.hasMore
    ) {
      setLoading(true)
      useTeamViewStore
        .getState()
        .tickets.loadMoreTopIssueTickets(issue.issueId, teamId, batchId, offset)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [teamId, batchId, issue.issueId, ticketIdsSnapshot])

  const doubleConfirmRemoveTicketRef = useRef<INewTicketData | undefined>()
  const [removing, setRemoving] = useState(false)
  const handleRemoveTicket = useCallback(
    (feedbackId: string) => {
      if (batchId && issue.issueId && feedbackId) {
        setRemoving(true)
        app.store.actions
          .removeTicketFromTopIssue(batchId, issue.issueId, feedbackId)
          .then(() => {
            setTableItems((pre) => pre.filter((item) => item.ticketId !== feedbackId))
          })
          .finally(() => {
            setRemoving(false)
            setDialogOpen(false)
          })
      }
    },
    [batchId, issue.issueId],
  )

  const table = useMemo(() => {
    if (ticketIdsSnapshot?.status === 'waiting') {
      return <TableLoading className={styles.loading} style={{ height: estimatedHeight }} />
    }
    if (ticketIdsSnapshot?.status === 'error') {
      return <ErrorView error={ticketIdsSnapshot.error} />
    }
    if (ticketsSnapshot?.status === 'done' && ticketIdsSnapshot?.status === 'done') {
      return ticketIdsSnapshot.data?.ticketCount > 0 ? (
        <Row>
          <AGGridTable
            ref={(ref) => tableRef?.(ref)}
            rowData={tableItems}
            getRowId={(data) => data.data.ticketId}
            columnDefs={columns}
            onRowClicked={onRowClick}
            focusedRowId={clickedTicketKey}
            domLayout="autoHeight"
            suppressRowVirtualisation={true}
            rowSelectionMode="multiRow"
            onSelectionChange={onSelectionChange}
            handleColumnsChange={handleColumnsChange}
            stickySuffixRenderer={(rowData: INewTicketData) => (
              <DialogTrigger disableButtonEnhancement>
                <Button
                  onClick={() => {
                    doubleConfirmRemoveTicketRef.current = rowData
                  }}
                  icon={<LinkDismissRegular />}
                  style={{ width: 'max-content' }}
                  appearance="transparent"
                >
                  Remove from Issue
                </Button>
              </DialogTrigger>
            )}
          />
        </Row>
      ) : (
        <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
      )
    }
    return null
  }, [
    ticketsSnapshot,
    ticketIdsSnapshot,
    styles.loading,
    estimatedHeight,
    tableItems,
    columns,
    onRowClick,
    clickedTicketKey,
    onSelectionChange,
    tableRef,
    handleColumnsChange,
  ])

  const hasMore =
    ticketIdsSnapshot?.data?.hasMore &&
    ticketIdsSnapshot.data.ticketCount - (ticketIdsSnapshot.data?.ticketIds?.length ?? 0) > 0

  useEffect(() => {
    // clear tickets in store when unmount
    return () => {
      useTeamViewStore.getState().tickets.clearTopIssueTickets(`${batchId}-${issue.issueId}`)
    }
  }, [issue.issueId, batchId])

  const routePath = useMemo(() => {
    const currentArgs = useTeamViewStore.getState().route.args
    if (!currentArgs || !batchId || !issue.issueId) return ''
    return SearchRoute.navigator.generatePath({
      product: currentArgs.product,
      tab: 'feedbackInsights',
      issueBatchId: batchId,
      issueId: issue.issueId,
    })
  }, [issue.issueId, batchId])

  if (ticketIdsSnapshot?.status === 'done' && ticketIdsSnapshot.data?.ticketCount === 0) {
    return null
  }

  return (
    <Dialog
      modalType="alert"
      open={dialogOpen}
      onOpenChange={(_, data) => {
        setDialogOpen(data.open)
        if (!data.open) {
          doubleConfirmRemoveTicketRef.current = undefined
        }
      }}
    >
      <Column className={styles.wrapper}>
        <Row ref={rowRef} id={issue.issueId} className={styles.accordionHeader} onClick={() => setExpanded((v) => !v)}>
          <Row className={styles.gridRow}>
            <motion.div className={styles.icon} animate={{ rotate: expanded ? 90 : 0 }}>
              <ChevronRightFilled fontSize={22} />
            </motion.div>
            <Spacer width={8} />
            <Text size={400} weight="semibold">
              <Tag style={{ marginRight: '12px', width: '40px', height: '22px' }}>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: '14px',
                    color:
                      issue.priority === 0
                        ? tokens.colorStatusDangerForeground1
                        : issue.priority === 1
                          ? tokens.colorPaletteCornflowerForeground2
                          : tokens.colorNeutralStrokeAccessibleHover,
                  }}
                >
                  P{issue.priority}
                </Text>
              </Tag>
              Issue: {issue.issueTitle}
              {isAllTeams ? null : (
                <RouteLink
                  title="Jump to the relevant feedback insights"
                  path={routePath}
                  style={{ marginLeft: '16px', marginRight: '16px', verticalAlign: 'middle' }}
                >
                  <OrganizationRegular fontSize={20} />
                </RouteLink>
              )}
              <Link
                style={{ marginLeft: '4px', marginRight: '16px', verticalAlign: 'middle' }}
                rel="noopener"
                target="_blank"
                href="https://forms.office.com/r/GZTQXzvU5N"
                appearance="subtle"
                title="Provide issue feedback"
                onClick={(e) => {
                  e.stopPropagation()
                  batchId &&
                    Logger.telemetry.trackEvent('TopIssues/ClickFeedback', {
                      issueId: issue.issueId,
                      issueBatchId: batchId,
                      product: useTeamViewStore.getState().route.args?.product,
                      teamId: Number(teamId),
                      teamName: teamName,
                      location: 'TeamView/TopIssues',
                    })
                }}
              >
                <PenSparkleRegular fontSize={18} />
              </Link>
            </Text>
          </Row>
          <Row className={styles.gridRow}>{issue.coverage}%</Row>
          <Row className={styles.gridRow}>{issue.ticketCount}</Row>
          <Row className={styles.gridRow}>{issue.impactedTenantsCount}</Row>
        </Row>
        <motion.div
          layout
          animate={expanded ? 'expanded' : 'collapsed'}
          className={styles.accordionContent}
          variants={{
            collapsed: { height: 0, paddingTop: 0, overflow: 'hidden' },
            expanded: { height: 'auto', paddingTop: 16 },
          }}
          initial={false}
        >
          {table}
          {hasMore && (
            <Row hAlign="center" className={styles.loadMore}>
              {loading ? (
                <Spinner />
              ) : (
                <Button
                  className={styles.loadMoreTickets}
                  onClick={handleLoadMore}
                  icon={<ChevronDownRegular />}
                  iconPosition="after"
                >
                  Load more tickets (
                  {ticketIdsSnapshot.data.ticketCount - (ticketIdsSnapshot.data?.ticketIds?.length ?? 0)})
                </Button>
              )}
            </Row>
          )}
        </motion.div>
      </Column>
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <Text size={400} weight="semibold">
              Are you sure to remove the DSAT from the current issue clustering?
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              disabled={removing}
              icon={removing ? <Spinner size="extra-tiny" /> : null}
              onClick={() => {
                doubleConfirmRemoveTicketRef.current &&
                  handleRemoveTicket(doubleConfirmRemoveTicketRef.current.ticketId)
              }}
            >
              Remove
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

export const TopIssueTickets = memo(TopIssueTicketsComp)

TopIssueTickets.displayName = 'TopIssueTickets'
