import { ITicketMessageContent } from '@copilot-dash/domain'
import { TranslationIndicator } from '../../../../components/Translation/TranslationIndicator'
import { useTranslation } from '../../../../components/Translation/useTranslation'

interface IProps {
  readonly content: ITicketMessageContent
}

export function TicketScreenVerbatimContent({ content }: IProps) {
  const translation = useTranslation(content.text, content.textInEnglish)

  return (
    <span>
      <TranslationIndicator isTranslated={translation.isTranslated} />
      <span>{translation.value}</span>
    </span>
  )
}
