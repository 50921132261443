import { Button, makeStyles, tokens, Tooltip } from '@fluentui/react-components'
import { TranslateFilled, TranslateRegular } from '@fluentui/react-icons'
import { Row } from '../../../../components/Layout'

export function HeaderTranslationButton() {
  const styles = useStyles()
  const enableTranslationEntry = app.features.translationButtonVisible.use()
  const enableTranslation = app.settings.translateContentToEnglish.use()
  if (!enableTranslationEntry) {
    return null
  }

  const handleOnToggle = () => {
    app.settings.translateContentToEnglish.set(!enableTranslation)
  }

  return (
    <Tooltip content={<TooltipContent />} relationship={'label'}>
      <Button
        onClick={handleOnToggle}
        icon={enableTranslation ? <TranslateFilled className={styles.enabled} /> : <TranslateFilled />}
        appearance="transparent"
      />
    </Tooltip>
  )
}

function TooltipContent() {
  const enabled = app.settings.translateContentToEnglish.use()

  return (
    <Row vAlign="center" gap="gap.smaller">
      <TranslateRegular fontSize={20} />
      {!enabled && (
        <span>
          Translate to <b>English</b>
        </span>
      )}
      {enabled && <span>See original language</span>}
    </Row>
  )
}

const useStyles = makeStyles({
  enabled: {
    color: tokens.colorBrandForeground1,
  },
})
