import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { IRootCauseInfoById, ProductIds, TeamId } from '@copilot-dash/domain'
import { UnknownError } from '@copilot-dash/error'

export function getOrFetchRootCauseInfoByRootCauseId(
  context: IDashStoreContext,
  rootCauseId: string,
  vsoAccount: string,
): PromiseSnapshot<IRootCauseInfoById> {
  return context.getOrFetch({
    get: (state) => {
      return state.team.rootCauseInfoMap?.[`${rootCauseId}-${vsoAccount}`]
    },
    set: (state, snapshot) => {
      if (!state.team.rootCauseInfoMap) {
        state.team.rootCauseInfoMap = {}
      }
      state.team.rootCauseInfoMap[`${rootCauseId}-${vsoAccount}`] = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getRootCauseInfoById({
        RootCauseId: rootCauseId,
        VsoAccount: vsoAccount,
      })
      if (response.errorMessage) {
        throw UnknownError.create(response.errorMessage)
      }
      return {
        ...response.info,
        productId: String(response.info.productId) as ProductIds,
        teamId: String(response.info.teamId) as TeamId,
      }
    },
  })
}
