import { TeamId, Email } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getTeamDRIListByTeamId(context: IDashStoreContext, teamId: TeamId): Promise<Email[]> {
  if (context.enableV2TeamView) {
    const response = await context.api.copilotDash.getFeatureTeams(teamId)
    return response.driList
  } else {
    const response = await context.api.logCollector.getTeamDRIListByTeamId({ teamId: Number(teamId) })
    return response.driList
  }
}

export async function setTeamDRIList(context: IDashStoreContext, teamId: TeamId, params: Email[]): Promise<void> {
  if (context.enableV2TeamView) {
    await context.api.copilotDash.patchFeatureTeams(teamId, {
      propertyList: [
        {
          propertyName: 'driList',
          value: params.join(','),
        },
      ],
    })
    return
  } else {
    await context.api.logCollector.setTeamDRIList({ TeamId: Number(teamId), driList: params })
    return
  }
}
