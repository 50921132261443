import { ITicketMessageContent } from '@copilot-dash/domain'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { AdaptiveCard } from '../../../../AdaptiveCard/AdaptiveCard'
import { MarkdownView } from '../../../../Markdown/MarkdownView'
import { Scrollbar } from '../../../../Scrollbar/Scrollbar'

/**
 * We use an empty space to display when the message content is empty to prevent the message content from collapsing.
 */
const EMPTY_PLACEHOLDER = `&nbsp;`

interface IProps {
  readonly content: ITicketMessageContent
}

export function MessageContent({ content }: IProps) {
  const styles = useStyles()

  return (
    <Scrollbar className={styles.root}>
      {(() => {
        // 1. AdaptiveCard
        if (content.adaptiveCard) {
          return <AdaptiveCard payload={content.adaptiveCard} />
        }

        // 2. Markdown
        if (content.markdown) {
          return (
            <MarkdownView className={styles.markdown} enableImagePreview>
              {content.markdown}
            </MarkdownView>
          )
        }

        // 3. Text
        return <div className={styles.text}>{content.text || EMPTY_PLACEHOLDER}</div>
      })()}
    </Scrollbar>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0px 12px',
    backgroundColor: tokens.colorNeutralBackground2,
    borderRadius: tokens.borderRadiusLarge,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
  },
  markdown: {
    color: tokens.colorNeutralForeground1,
    wordBreak: 'break-word',

    '&> p': {
      marginTop: '12px',
      marginBottom: '12px',
    },

    '&> pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  text: {
    color: tokens.colorNeutralForeground1,
    wordBreak: 'break-word',
    padding: '6px 0',
  },
})
