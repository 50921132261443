import {
  ColDef,
  DomLayoutType,
  GetRowIdFunc,
  RowHeightParams,
  AllCommunityModule,
  ModuleRegistry,
  ProcessRowParams,
  themeQuartz,
  RowClickedEvent,
  ViewportChangedEvent,
  IsFullWidthRowParams,
  GridSizeChangedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
  NumberEditorModule,
  TextEditorModule,
  TextFilterModule,
  NumberFilterModule,
  ClientSideRowModelModule,
  ColumnMovedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useThemeMode } from '../../theme/useThemeMode'
import { mergeClasses, Portal, tokens } from '@fluentui/react-components'
import { useStyles } from './AGGridTable.styles'
import {
  forwardRef,
  memo,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { getOrCreateStickySuffixContainer } from './utils'
import { LoadingRow } from './LoadingRow'
import { LoadingTable } from './LoadingTable'
import { debounce } from 'lodash'
import { ErrorView } from '../Error'

interface IProps<TItem> {
  readonly rowData: TItem[]
  readonly getRowId: GetRowIdFunc<TItem>
  readonly columnDefs: ColDef<TItem>[]
  readonly hasMore?: boolean
  readonly loadMore?: (offset: number) => void
  readonly domLayout?: DomLayoutType
  readonly className?: string
  readonly getRowHeight?: (params: RowHeightParams) => number
  readonly rowHeight?: number
  readonly onRowClicked?: (item: TItem) => void
  readonly focusedRowId?: string
  readonly processRowPostCreate?: (params: ProcessRowParams<TItem>) => void
  readonly stickySuffixRenderer?: (item: TItem) => JSX.Element
  readonly suppressRowVirtualisation?: boolean
  readonly suppressDragLeaveHidesColumns?: boolean
  readonly rowBuffer?: number
  readonly rowSelectionMode?: 'singleRow' | 'multiRow'
  readonly onSelectionChange?: (selectedItems: TItem[]) => void
  readonly handleOrderChange?: (order?: Array<string>) => void
  readonly loading?: boolean
  readonly handleColumnsChange: (value: Array<string>) => void
}

export interface IAGGridTableRef {
  clearSelection: () => void
}

// Register all Community features
ModuleRegistry.registerModules([
  AllCommunityModule,
  NumberEditorModule,
  TextEditorModule,
  TextFilterModule,
  NumberFilterModule,
  ClientSideRowModelModule,
])

const copilotDashTheme = themeQuartz.withParams({
  rowHoverColor: tokens.colorNeutralBackground3Hover,
  rangeSelectionBorderColor: 'transparent',
  rowHeight: 56, // set the default row height to 56px
})

function setDarkMode(enabled: boolean) {
  document.body.dataset.agThemeMode = enabled ? 'dark' : 'light'
}

const DEFAULT_COLUMN_DEF = {
  suppressAutoSize: true,
  sortable: false,
  resizable: true,
  suppressSizeToFit: true,
  comparator: () => 0, // Disable basic sort function in ag-grid table component by making all values equal
}

const AGGridTableComponent = <TItem,>(props: IProps<TItem>, ref: Ref<IAGGridTableRef>) => {
  const {
    rowData,
    getRowId,
    columnDefs: defaultColumnDefs,
    domLayout,
    className,
    hasMore = false,
    rowHeight = 56,
    getRowHeight,
    onRowClicked,
    focusedRowId,
    stickySuffixRenderer,
    rowSelectionMode,
    suppressRowVirtualisation = false,
    suppressDragLeaveHidesColumns = true,
    rowBuffer = 26,
    loading,
    handleColumnsChange,
  } = props
  const propsRef = useRef<IProps<TItem>>(props)
  propsRef.current = props

  const styles = useStyles()

  const LOADING_ROW_DATA_REF = useRef({})
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const preRowDataBeforeLoadMore = useRef<TItem[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const themeMode = useThemeMode()
  useEffect(() => {
    setDarkMode(themeMode === 'dark')
  }, [themeMode])

  const [suffixContainers, setSuffixContainers] = useState<Array<{ dom: HTMLElement; data: TItem }>>([])
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs)

  const onColumnMoved = (event: ColumnMovedEvent) => {
    if (!event.finished) return
    if (!gridRef.current) return
    const cols = gridRef.current.api.getAllGridColumns()
    const movedColumns = cols.map((column) => column.getId())
    handleColumnsChange(movedColumns)
  }
  useEffect(() => {
    setColumnDefs(defaultColumnDefs)
  }, [defaultColumnDefs])

  const rowDataWithLoadMore = useMemo(() => {
    if (loading) return []
    if (!hasMore) return rowData
    return [...rowData, LOADING_ROW_DATA_REF.current] as TItem[]
  }, [loading, hasMore, rowData])

  useLayoutEffect(() => {
    if (!gridRef.current?.api) return
    // Refresh grid when rowDataWithLoadMore changes.
    gridRef.current.api.setGridOption('rowData', rowDataWithLoadMore)
    gridRef.current.api.refreshCells({ force: true })
  }, [rowDataWithLoadMore])

  const loadingRenderer = useCallback(LoadingRow, [])

  const freezeColumnWidth = useCallback(() => {
    if (!gridRef.current) return
    const colState = gridRef.current.api.getColumnState()
    const newColState = colState.map((col) => {
      return { ...col, flex: 0 }
    })
    gridRef.current.api.applyColumnState({ state: newColState, applyOrder: true })
  }, [])

  const handleFirstDataRendered = useCallback(() => {
    // Freeze column width after first data rendered to prevent column width contraction when container resizes smaller.
    freezeColumnWidth()
  }, [freezeColumnWidth])

  const rowDataWithLoadMoreRef = useRef(rowDataWithLoadMore)
  rowDataWithLoadMoreRef.current = rowDataWithLoadMore
  const handleViewportChanged = useCallback((evt: ViewportChangedEvent<TItem>) => {
    if (!gridContainerRef.current) return
    const { hasMore = false, loadMore, rowData, rowHeight = 56, stickySuffixRenderer } = propsRef.current

    // Load more data if the last row is loaded
    if (hasMore && evt.lastRow === rowDataWithLoadMoreRef.current.length - 1) {
      if (preRowDataBeforeLoadMore.current !== rowData) {
        loadMore?.(rowData.length)
        preRowDataBeforeLoadMore.current = rowData
      }
    }

    if (!stickySuffixRenderer) return

    // Update sticky suffix containers for each row
    const nodes = evt.api.getRenderedNodes()
    const newSuffixContainers: Array<{ dom: HTMLElement; data: TItem }> = []
    for (const node of nodes) {
      const rowElement = gridContainerRef.current.querySelector(
        `.ag-row[row-index="${node.sourceRowIndex}"]`,
      ) as HTMLElement
      if (!rowElement) continue

      const suffixContainer = getOrCreateStickySuffixContainer(rowElement)
      suffixContainer.classList.add('copilotDashRowShadow')
      suffixContainer.style.height = `${rowHeight}px`
      node.data && newSuffixContainers.push({ dom: suffixContainer, data: node.data })
    }
    setSuffixContainers(newSuffixContainers)
  }, [])

  const isFullWidthRow = useCallback(
    (params: IsFullWidthRowParams<TItem>) => {
      return (
        hasMore &&
        params.rowNode.sourceRowIndex === rowDataWithLoadMore.length - 1 &&
        params.rowNode.data === LOADING_ROW_DATA_REF.current
      )
    },
    [hasMore, rowDataWithLoadMore.length],
  )

  const debounceAutoFitColumns = useRef(
    debounce(
      () => {
        if (!gridRef.current) return
        // update columnDefs to trigger auto fit columns
        setColumnDefs((preDefs) => [...preDefs])
      },
      100,
      { trailing: true },
    ),
  )

  /**
   * Listeners for grid events
   */

  // Auto fit columns when grid width increases.
  const onGridSizeChanged = useCallback((evt: GridSizeChangedEvent<TItem>) => {
    if (!gridRef.current) return
    const columnsActualWidth = gridRef.current.api.getColumnState().reduce((acc, col) => acc + (col.width ?? 0), 0)
    const gridWidth = evt.clientWidth
    const offset = 14 + (propsRef.current.rowSelectionMode ? 50 : 0) // 14px for scrollbar, 50px for selection column
    if (columnsActualWidth + offset < gridWidth) {
      debounceAutoFitColumns.current()
    }
  }, [])
  const onSelectionChanged = useCallback((evt: SelectionChangedEvent<TItem>) => {
    if (evt.source === 'api') return

    // Remove loading row from selected rows
    const rowsData = evt.api.getSelectedRows().filter((item) => item !== LOADING_ROW_DATA_REF.current)
    propsRef.current.onSelectionChange?.(rowsData)
  }, [])

  /**
   * Refs for functions that need to be passed to AgGridReact without being recreated on every render
   */
  const getRowIdRef = useRef(getRowId)
  const getRowHeightRef = useRef(getRowHeight)
  const onRowClickedRef = useRef((evt: RowClickedEvent<TItem>) => {
    if (onRowClicked && evt.data) {
      onRowClicked(evt.data)
    }
  })
  const rowSelectionRef = useRef(rowSelectionMode ? { mode: rowSelectionMode } : undefined)

  // handle sort state of column changes.
  const handleSortChanged = useCallback((evt: SortChangedEvent<TItem>) => {
    const sortModel = evt.columns
    if (sortModel) {
      const order = sortModel
        .filter((col) => col.getSort() !== undefined && col.getSort() !== null)
        .map((col) => `${col.getColId()} ${col.getSort()}`)
      propsRef.current.handleOrderChange?.(order)
    }
  }, [])

  /**
   * export clearSelection function
   */
  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      if (gridRef.current) {
        gridRef.current.api.deselectAll('all', 'api')
      }
    },
  }))

  return (
    <div ref={gridContainerRef} className={mergeClasses(styles.root, className)}>
      <AgGridReact<TItem>
        ref={gridRef}
        theme={copilotDashTheme}
        defaultColDef={DEFAULT_COLUMN_DEF}
        columnDefs={columnDefs}
        domLayout={domLayout}
        isFullWidthRow={isFullWidthRow}
        fullWidthCellRenderer={loadingRenderer}
        suppressAutoSize={true}
        suppressRowVirtualisation={suppressRowVirtualisation}
        suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
        rowData={rowDataWithLoadMore}
        rowBuffer={rowBuffer}
        rowHeight={rowHeight}
        rowSelection={rowSelectionRef.current}
        rowClass={styles.row}
        rowClassRules={{
          [styles.highlight]: (params) => params.node.id === focusedRowId,
        }}
        getRowId={getRowIdRef.current}
        getRowHeight={getRowHeightRef.current}
        onRowClicked={onRowClickedRef.current}
        onFirstDataRendered={handleFirstDataRendered}
        onViewportChanged={handleViewportChanged}
        onNewColumnsLoaded={freezeColumnWidth}
        onGridSizeChanged={onGridSizeChanged}
        onSelectionChanged={onSelectionChanged}
        onSortChanged={handleSortChanged}
        multiSortKey={'ctrl'}
        animateRows={false}
        loading={loading}
        loadingOverlayComponent={LoadingTable}
        noRowsOverlayComponent={ErrorView.Custom}
        noRowsOverlayComponentParams={{ level: 'WARNING', message: 'No tickets found for this query' }}
        onColumnMoved={onColumnMoved}
      />
      {stickySuffixRenderer &&
        suffixContainers.map((container, index) => (
          <Portal key={index} mountNode={container.dom}>
            {stickySuffixRenderer && stickySuffixRenderer(container.data)}
          </Portal>
        ))}
    </div>
  )
}

AGGridTableComponent.displayName = 'AGGridTable'

export const AGGridTable = memo(forwardRef(AGGridTableComponent)) as <TItem>(
  props: IProps<TItem> & { ref?: Ref<IAGGridTableRef> },
) => JSX.Element
