import { SearchFilterFieldNames } from '@copilot-dash/domain'

export const AgentCardFilters: SearchFilterFieldNames[] = [
  'agentTypes',
  'appTypes',
  'hasCopilotExtensionIds',
  'copilotExtensionIds',
]

export const FeedbackSourceCardFilters: SearchFilterFieldNames[] = [
  'applications',
  'platforms',
  'licenses',
  'meetingScenarios',
  'channel',
  'customerTypes',
  'ring',
]

export const FeedbackDetailCardFilters: SearchFilterFieldNames[] = [
  'thumbs',
  'hasVerbatim',
  'hasUserConsent',
  'hasScreenshot',
]

export const PromptDetailsCardFilters: SearchFilterFieldNames[] = [
  'promptLanguages',
  'groundedPrompts',
  'invocationSlicers',
  'invocationType',
]

export const QueryProcessingCardFilters: SearchFilterFieldNames[] = [
  'triggeredSkill',
  'hitAvalon',
  'optionsSets',
  'sliceIds',
  'flights',
]

export const FeedbackHandlingCardFilters: SearchFilterFieldNames[] = [
  'priority',
  'dSATStatus',
  'customTags',
  'dSATAssignedTo',
]

export const CustomFilterCardFilters: SearchFilterFieldNames[] = [
  'isTopi18N',
  'isSTCAChina',
  'hasGPTExtension',
  'hasMessageExtension',
  'hasConnector',
  'isGCIntent',
  'hasConnectorResult',
  'semanticSearchType',
  'experienceType',
  'errorCode',
]

export const ResponseDetailsCardFilters: SearchFilterFieldNames[] = [
  'isApology',
  'hasCitation',
  'hasErrorMessages',
  'hasEntityCard',
  'responseHeroType',
  'responseLinkType',
]
