import {
  ApiConversationGroup1,
  ApiConversationGroup1AdaptiveCard,
  ApiConversationGroup1Message,
} from '@copilot-dash/api'
import {
  ITicketMessageForBot,
  ITicketMessageForInvocation,
  ITicketMessageForSuggestion,
  ITicketMessageForUser,
  ITicketTurnConversation,
} from '@copilot-dash/domain'
import { compact, isEmpty } from 'lodash'
import { z } from 'zod'

export function convertTicketChatTurnFromConversationGroup1(
  turnId: string,
  api: ApiConversationGroup1,
): ITicketTurnConversation {
  return {
    id: turnId,
    timestamp: getTimeStamp(),
    utterance: getUserMessage(),
    response: getBotMessage(),
    invocations: getInvocationMessages(),
    suggestions: getSuggestions(),
    source: 'conversation-group1',
    raw: api,
  }

  function getTimeStamp(): string | undefined {
    for (const message of api.conversation.messages) {
      if (message.createdAt) {
        return message.createdAt
      }
    }

    return undefined
  }

  function getUserMessage(): ITicketMessageForUser | undefined {
    const message = api.conversation.messages[0]
    if (!message) {
      return undefined
    }

    return {
      content: {
        text: getMessageText(message.text, message.adaptiveCards?.[0]?.body?.[0]?.text) ?? '',
        textInEnglish: undefined,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: getMessageAdaptiveCards(message.adaptiveCards?.[0]),
      },
      timestamp: message.createdAt,
    }
  }

  function getBotMessage(): ITicketMessageForBot | undefined {
    const possibleMessages = api.conversation.messages.filter((item, index) => index > 0 && item.text).reverse()

    let message: ApiConversationGroup1Message | undefined
    message ??= possibleMessages.find((item) => !isEmpty(item.suggestedResponses) && !isEmpty(item.adaptiveCards))
    message ??= possibleMessages.find((item) => !isEmpty(item.suggestedResponses))
    message ??= possibleMessages.find((item) => !isEmpty(item.adaptiveCards))
    message ??= possibleMessages.find((item) => item.suggestedResponses && item.adaptiveCards)
    message ??= possibleMessages.find((item) => item.suggestedResponses)
    message ??= possibleMessages.find((item) => item.adaptiveCards)
    message ??= possibleMessages[0]

    if (!message) {
      return undefined
    }

    const text = getMessageText(message.text, message.adaptiveCards?.[0]?.body?.[0]?.text)
    return {
      content: {
        text: text || '',
        textInEnglish: undefined,
        markdown: text,
        markdownInEnglish: undefined,
        adaptiveCard: getMessageAdaptiveCards(message.adaptiveCards?.[0]),
      },
      timestamp: message.createdAt,
    }
  }

  function getInvocationMessages(): ITicketMessageForInvocation[] {
    const invocations = compact(api.conversation.messages.map((item) => item.invocation))
    return invocations.map((item) => ({ text: item }))
  }

  function getSuggestions(): ITicketMessageForSuggestion[] {
    for (const message of api.conversation.messages) {
      if (message.suggestedResponses && message.suggestedResponses.length > 0) {
        return message.suggestedResponses.map((item) => {
          return {
            text: item.text,
          }
        })
      }
    }

    return []
  }

  function getMessageText(...values: unknown[]): string | undefined {
    for (const value of values) {
      if (value && typeof value === 'string') {
        return tryParseAdaptiveCardMessage(value)
      }
    }

    return ''
  }

  function getMessageAdaptiveCards(instance: ApiConversationGroup1AdaptiveCard | undefined): object | undefined {
    try {
      // TODO: This is a temporary solution to handle OPG bugs!
      // Needs to be removed once the OPG fixes the issue.
      // If text field is a json string and can be parsed, then it is NOT a valid adaptive card.
      const text = instance?.body?.[0]?.text
      if (text) {
        adaptiveCardMessageSchema.parse(JSON.parse(text))
        return undefined
      }
    } catch {
      // do nothing
    }

    return instance
  }

  function tryParseAdaptiveCardMessage(text: string): string {
    // TODO: This is a temporary solution to handle OPG bugs!
    // Needs to be removed once the OPG fixes the issue.
    try {
      const jsonObject = JSON.parse(text)
      const message: AdaptiveCardMessage = adaptiveCardMessageSchema.parse(jsonObject)
      return message.body.message || message.body.textContent || message.body.messageHeader?.text || text
    } catch (error) {
      return text
    }
  }
}

interface AdaptiveCardMessage {
  readonly body: AdaptiveCardMessageBody
}

interface AdaptiveCardMessageBody {
  readonly message?: string
  readonly textContent?: string
  readonly messageHeader?: {
    readonly text?: string
  }
}

const adaptiveCardMessageBodySchema = z.object({
  message: z.string().optional(),
  textContent: z.string().optional(),
  messageHeader: z
    .object({
      text: z.string().optional(),
    })
    .optional(),
})

const adaptiveCardMessageSchema = z.object({
  body: adaptiveCardMessageBodySchema,
})
