import { FC, memo, useEffect, useMemo } from 'react'
import { Column, Spacer } from '../Layout'
import { ProductIds, ITeamData, TeamId } from '@copilot-dash/domain'
import { Controller, useForm } from 'react-hook-form'
import { debounce, isNil } from 'lodash'
import { Title } from './fields/Title'
import { Product } from './fields/Product'
import { Team } from './fields/Team'
import { Query } from './fields/Query'

export enum FieldNames {
  QueryId = 'queryId',
  Title = 'title',
  ProductId = 'productId',
  IsTeamShared = 'isTeamShared',
  TeamId = 'teamId',
  CopilotDashQuery = 'copilotDashQuery',
  CopilotDashPath = 'copilotDashPath',
  FilterPayload = 'filterPayload',
}

export interface IFormData {
  [FieldNames.Title]: string
  [FieldNames.IsTeamShared]: boolean
  [FieldNames.TeamId]?: TeamId
  [FieldNames.ProductId]: ProductIds
  [FieldNames.QueryId]?: string
  [FieldNames.CopilotDashQuery]: string
  [FieldNames.CopilotDashPath]: string
  [FieldNames.FilterPayload]: string
}
const DEFAULT_VALUE: IFormData = {
  [FieldNames.Title]: '',
  [FieldNames.ProductId]: ProductIds.M365Chat,
  [FieldNames.IsTeamShared]: false,
  [FieldNames.CopilotDashQuery]: '',
  [FieldNames.CopilotDashPath]: '',
  [FieldNames.FilterPayload]: '',
}

interface IProps {
  afterValidate: (isValid: boolean, data: IFormData) => void
  defaultValue?: IFormData
  fields?: FieldNames[]
  teamsOptions: ITeamData[]
  disabled?: {
    [key in FieldNames]?: boolean
  }
  isUpdate: boolean
}

export const SaveQueryForm: FC<IProps> = memo(
  ({
    afterValidate,
    defaultValue = DEFAULT_VALUE,
    fields = [FieldNames.Title, FieldNames.TeamId],
    teamsOptions,
    disabled,
    isUpdate,
  }) => {
    const { control, trigger, watch } = useForm({
      mode: 'onChange',
      defaultValues: defaultValue,
    })

    const debouncedValidate = useMemo(() => {
      return debounce(async (value: IFormData) => {
        const isValid = await trigger()

        afterValidate(isValid, value)
      }, 200)
    }, [afterValidate, trigger])

    useEffect(() => {
      const subscription = watch((value, { type }) => {
        if (type === 'change') {
          debouncedValidate(value as IFormData)
        }
      })
      isUpdate && debouncedValidate(defaultValue)
      return () => subscription.unsubscribe()
    }, [debouncedValidate, isUpdate, defaultValue, watch])

    return (
      <Column>
        <Spacer height="12px" />
        <Controller
          name={FieldNames.ProductId}
          control={control}
          render={({ field }) => {
            return <Product value={field.value || ProductIds.M365Chat} />
          }}
        />
        <Spacer height="12px" />
        <Controller
          name={FieldNames.Title}
          control={control}
          rules={{ required: 'Required' }}
          render={({ field, fieldState }) => {
            return (
              <Title
                value={field.value}
                onChange={field.onChange}
                fieldProps={{
                  validationMessage: fieldState.error?.message,
                }}
              />
            )
          }}
        />
        <Spacer height="12px" />

        <Controller
          name={FieldNames.IsTeamShared}
          control={control}
          render={({ field }) => (
            <Controller
              name={FieldNames.TeamId}
              control={control}
              rules={{
                validate: (value) => {
                  if (field.value && isNil(value)) {
                    return 'Team is required when sharing with team'
                  }
                  return true
                },
              }}
              render={({ field: teamField, fieldState }) => (
                <Team
                  teamId={teamField.value}
                  isTeamShared={field.value}
                  onTeamIdChange={teamField.onChange}
                  onIsTeamSharedChange={field.onChange}
                  fieldProps={{
                    validationMessage: fieldState.error?.message,
                  }}
                  teamsOptions={teamsOptions}
                  disabled={disabled?.isTeamShared}
                />
              )}
            />
          )}
        />

        <Spacer height="12px" />
        <Controller
          name={FieldNames.CopilotDashQuery}
          control={control}
          //rules={{ required: 'Required' }}
          render={({ field, fieldState }) => {
            return <Query value={field.value} />
          }}
        />
      </Column>
    )
  },
)

SaveQueryForm.displayName = 'SaveQueryForm'
