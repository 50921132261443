import { z } from 'zod'
import { ApiV2RootCausesResponse, apiV2RootCausesResponseSchema } from './ApiV2RootCausesResponse'

export interface ApiV2WorkItemsResponse {
  readonly adoWorkItemId?: string
  readonly dsatStatus: string
  readonly priority: string
  readonly teamId: number
  readonly assignTo?: string
  readonly adoWorkItemLink?: string
  readonly rootCauseList: ApiV2RootCausesResponse
  readonly closedComment?: string
}

export const apiV2WorkItemsResponseSchema = z.object({
  adoWorkItemId: z.string().optional(),
  dsatStatus: z.string(),
  priority: z.string(),
  teamId: z.number(),
  assignTo: z.string().optional(),
  adoWorkItemLink: z.string().optional(),
  rootCauseList: apiV2RootCausesResponseSchema,
  closedComment: z.string().optional(),
})
