import {
  ApplicationFilter,
  AuthTypeFilter,
  ChannelFilter,
  CustomerFilter,
  LicenseFilter,
  MeetingScenarioFilter,
  PlatformFilter,
  RingFilter,
} from '../filters'
import { Control, Controller } from 'react-hook-form'
import { Body1Stronger } from '@fluentui/react-components'
import { Card } from './Card'
import { FeedbackSourceCardFilters } from './config'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { useMemo } from 'react'
import { useStyles } from './Card.styles'
import { useTicketFilterStore } from '../../store/store'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function FeedbackSourceCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
  const selectedApplications = useTicketFilterStore((state) => state.selectedApplications)
  const selectedPlatforms = useTicketFilterStore((state) => state.selectedPlatforms)
  const selectedLicenses = useTicketFilterStore((state) => state.selectedLicenses)

  const hasFeedbackSourceCard = useMemo(() => {
    return filterPanelItems.some((item) => FeedbackSourceCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasFeedbackSourceCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Feedback source</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems?.includes('applications') && (
          <Controller
            name="applications"
            control={control}
            render={({ field }) => (
              <ApplicationFilter
                productId={selectedProductId}
                platforms={selectedPlatforms ?? []}
                licenses={selectedLicenses ?? []}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes('platforms') && (
          <Controller
            name="platforms"
            control={control}
            render={({ field }) => (
              <PlatformFilter
                productId={selectedProductId}
                applications={selectedApplications ?? []}
                licenses={selectedLicenses ?? []}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes('licenses') && (
          <Controller
            name="licenses"
            control={control}
            render={({ field }) => (
              <LicenseFilter
                productId={selectedProductId}
                applications={selectedApplications ?? []}
                platforms={selectedPlatforms ?? []}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes('meetingScenarios') && (
          <Controller
            name="meetingScenarios"
            control={control}
            render={({ field }) => (
              <MeetingScenarioFilter
                productId={selectedProductId}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes('channel') && (
          <Controller
            name="channel"
            control={control}
            render={({ field }) => (
              <ChannelFilter
                productId={selectedProductId}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes('authTypes') && (
          <Controller
            name="authTypes"
            control={control}
            render={({ field }) => <AuthTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('customerTypes') && (
          <Controller
            name="customerTypes"
            control={control}
            render={({ field }) => <CustomerFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('ring') && (
          <Controller
            name="ring"
            control={control}
            render={({ field }) => <RingFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
