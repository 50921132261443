import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketMessageContent } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOcvTicket } from '../actions-raw-ticket/getRawOcvTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketLastTurnMessageId } from '../actions-ticket-chat/getTicketLastTurnMessageId'
import { getTicketTurnConversation } from '../actions-ticket-chat/getTicketTurnConversation'

export function getTicketUtterance(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketMessageContent> {
  return context.getOrFetch<ITicketMessageContent>({
    get: (state) => {
      return state.tickets[ticketId]?.utterance
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.utterance = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketMessageContent> {
    if (context.enableV2Endpoint) {
      return fetchFromV2()
    }

    try {
      return await fetchFromConversation()
    } catch (error) {
      try {
        return await fetchFromOCV()
      } catch {
        throw error
      }
    }
  }

  async function fetchFromV2(): Promise<ITicketMessageContent> {
    await assertTicketHasUserConsent(context, ticketId)

    // 1. Get value
    const raw = await getRawV2Ticket(context, ticketId).promise
    const utterance = raw.diagnosticContext?.chat?.[0]?.utterance
    if (utterance) {
      return {
        text: utterance.text,
        textInEnglish: utterance.textInEnglish,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoUtterance', { ticketId })
  }

  async function fetchFromConversation(): Promise<ITicketMessageContent> {
    const turnId = await getTicketLastTurnMessageId(context, ticketId).promise
    const turn = await getTicketTurnConversation(context, ticketId, turnId).promise
    const utterance = turn.utterance?.content
    if (utterance) {
      return utterance
    }

    await assertTicketHasUserConsent(context, ticketId)
    throw TicketError.create('NoUtterance', { ticketId })
  }

  async function fetchFromOCV(): Promise<ITicketMessageContent> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise
    const prompt = ocvTicket.AiContext?.Prompt
    const promptInEnglish = ocvTicket.AiContext?.PromptInEnglish
    if (prompt || promptInEnglish) {
      return {
        text: prompt || promptInEnglish || '',
        textInEnglish: promptInEnglish,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoUtterance', { ticketId })
  }
}
