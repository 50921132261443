import { makeStyles, Tooltip, useId, Toaster, Button, mergeClasses } from '@fluentui/react-components'
import { Checkmark16Regular, Copy16Regular } from '@fluentui/react-icons'
import { useState, Fragment } from 'react'
import { CopilotDashPath, IQuery, getProductNameById } from '@copilot-dash/domain'
import { useToast } from '../../../../hooks/useToast'
import { SearchRoute, TeamRoute } from '../../../../router'

interface IProps {
  readonly query: IQuery
  className?: string
}

export function QueryCopyButton({ query, className }: IProps) {
  const styles = useStyles()
  const toasterId = useId('toaster')
  const toast = useToast()
  const [copied, setCopied] = useState(false)

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()

    Logger.telemetry.trackEvent('SavedQueries/Copy', {
      isTeamShared: query.isTeamShared,
      queryId: query.queryId,
      productId: Number(query.productId),
      teamId: Number(query.teamId),
    })

    const link =
      query.copilotDashPath === CopilotDashPath.search
        ? SearchRoute.navigator.generatePathString({
            queryId: query.queryId,
            product: getProductNameById(query.productId) || 'M365Chat',
          })
        : TeamRoute.navigator.generatePathString({
            queryId: query.queryId,
            product: getProductNameById(query.productId) || 'M365Chat',
          })

    navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}${link}`).then(() => {
      setCopied(true)
      toast.showSuccess('Link copied to clipboard')
      setTimeout(() => setCopied(false), 2000)
    })
  }

  return (
    <Fragment>
      <Tooltip content={copied ? 'Copied' : 'Copy'} relationship={'label'}>
        <Button
          appearance="transparent"
          className={styles.root}
          icon={
            copied ? (
              <Checkmark16Regular className={mergeClasses(className, 'queriesActionIcon')} />
            ) : (
              <Copy16Regular className={mergeClasses(className, 'queriesActionIcon')} />
            )
          }
          onClick={(e) => onClick(e)}
        />
      </Tooltip>
      <Toaster toasterId={toasterId} />
    </Fragment>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0',
    margin: '0',
    cursor: 'pointer',
    minWidth: '24px',
    width: '24px',
  },
})
