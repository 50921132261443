import { MoreHorizontal16Regular, Delete16Regular, Edit16Regular, Rename16Regular } from '@fluentui/react-icons'
import { IQuery, getProductNameById, CopilotDashPath } from '@copilot-dash/domain'
import {
  tokens,
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
} from '@fluentui/react-components'
import { useState } from 'react'
import { DeleteQueryDialog } from './DeleteQueryDialog'
import { SearchRoute, TeamRoute } from '../../../../router'
import { useTicketFilterStore, HeaderTab } from '../../store/store'
import { RenameQueryScreen } from '../dialog/RenameQueryScreen'
interface IProps {
  readonly query: IQuery
  readonly className?: string
}

export function QueryMoreActionButton({ query, className }: IProps) {
  const styles = useStyles()
  const [saveQueryDialogOpen, setSaveQueryDialogOpen] = useState(false)
  const [renameDialogOpen, setRenameDialogOpen] = useState(false)

  const onDeleteQuery = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()

    Logger.telemetry.trackEvent('SavedQueries/ClickDeleteQueryButton', {
      isTeamShared: query.isTeamShared,
      queryId: query.queryId,
      productId: Number(query.productId),
      teamId: Number(query.teamId),
    })
    setSaveQueryDialogOpen(true)
  }

  const onEditQuery = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    Logger.telemetry.trackEvent('SavedQueries/ClickEditQueryButton', {
      isTeamShared: query.isTeamShared,
      queryId: query.queryId,
      productId: Number(query.productId),
      teamId: Number(query.teamId),
    })

    useTicketFilterStore.getState().setSelectedHeaderTab(HeaderTab.Filters)
    if (query.copilotDashPath === CopilotDashPath.search) {
      SearchRoute.navigator.navigate({
        queryId: query.queryId,
        product: getProductNameById(query.productId) || 'M365Chat',
      })
    } else {
      TeamRoute.navigator.navigate({
        queryId: query.queryId,
        product: getProductNameById(query.productId) || 'M365Chat',
      })
    }
  }
  const onRenameQuery = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setRenameDialogOpen(true)
    Logger.telemetry.trackEvent('SavedQueries/ClickRenameQueryButton', {
      isTeamShared: query.isTeamShared,
      queryId: query.queryId,
      productId: Number(query.productId),
      teamId: Number(query.teamId),
    })
  }

  return (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton
            className={styles.button}
            appearance="transparent"
            size="small"
            icon={<MoreHorizontal16Regular className={mergeClasses(className, 'queriesActionIcon')} />}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          ></MenuButton>
        </MenuTrigger>
        <MenuPopover className={styles.container}>
          <MenuList>
            <MenuItem
              icon={<Rename16Regular className={styles.menuIcon} />}
              className={styles.menuItem}
              onClick={(e) => {
                onRenameQuery(e)
              }}
            >
              Rename Query
            </MenuItem>
            <MenuItem
              icon={<Edit16Regular className={styles.menuIcon} />}
              className={styles.menuItem}
              onClick={(e) => onEditQuery(e)}
            >
              Edit Query
            </MenuItem>
            <MenuItem
              icon={<Delete16Regular className={styles.menuIcon} />}
              onClick={(e) => onDeleteQuery(e)}
              className={styles.menuItem}
            >
              Delete Query
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
      <DeleteQueryDialog
        open={saveQueryDialogOpen}
        onClose={() => {
          setSaveQueryDialogOpen(false)
        }}
        query={query}
      />
      <RenameQueryScreen dialogOpen={renameDialogOpen} setDialogOpen={setRenameDialogOpen} query={query} />
    </>
  )
}

const useStyles = makeStyles({
  button: {
    padding: '0',
    margin: '0',
    cursor: 'pointer',
    minWidth: '16px',
    width: '16px',
  },
  menuItem: {
    fontSize: tokens.fontSizeBase200,
    margin: '0 ',
    padding: '0 8px',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  menuIcon: {
    padding: '0',
    margin: '0',
    cursor: 'pointer',
    minWidth: '4px',
    width: '16px',
    fontSize: '16px',
  },
  container: {
    minWidth: 'max-content',
    width: 'max-content',
    padding: '0',
    margin: '0 8px',
    overflow: 'hidden',
  },
})
