import { Button, Field, Skeleton, SkeletonItem, Tag, Text } from '@fluentui/react-components'
import { Dismiss16Filled } from '@fluentui/react-icons'
import { FC, memo, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Column, Row, Spacer } from '../../Layout'
import { useStyles } from './CustomTags.styles'
import { CustomTagsPopover } from './CustomTagsPopover'

interface ICustomTagProps {
  value: string[]
  orientation?: 'horizontal' | 'vertical'
  onChange: (value: string[]) => void
  className?: string
  style?: React.CSSProperties
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  defaultValueText?: string
}
export const CustomTags: FC<ICustomTagProps> = memo(
  ({ value, orientation = 'horizontal', className, style, fieldProps, onChange, defaultValueText }) => {
    const filterOptionsSnapshot = app.store.use.getAllCustomTags()
    const styles = useStyles()
    const [visibleTags, setVisibleTags] = useState(value && value.length > 0 ? value : [])
    const [focusDisplayValue, setFocusDisplayValue] = useState('')
    const [openSelect, setOpenSelect] = useState(false)

    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

    const onRemoveTag = (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const newValues = [...visibleTags].filter((tag) => tag !== value)
      onChange(newValues)
      Logger.telemetry.trackEvent('CustomTag/DeleteTag', { tags: newValues, deletedTag: value })
    }

    useLayoutEffect(() => {
      setVisibleTags(value && value.length > 0 ? value : [])
    }, [value])

    const onTagsChange = useCallback(
      (tag: string) => {
        const newValues = [...visibleTags]
        newValues.push(tag)
        onChange(newValues)
        setFocusDisplayValue('')
        setIsPopoverOpen(false)
        Logger.telemetry.trackEvent('CustomTag/AddTag', { tags: newValues, addedTag: tag })
      },
      [onChange, visibleTags, setFocusDisplayValue],
    )

    //filter the options based on the input value
    function filterOptions(optionsList: string[]) {
      const updatedOptionsList = [...optionsList]
      // append the focusDisplayValue to the options list if it is not in the list
      if (
        focusDisplayValue &&
        !visibleTags.includes(focusDisplayValue) &&
        !updatedOptionsList.includes(focusDisplayValue)
      ) {
        updatedOptionsList.push(focusDisplayValue)
      }
      const filteredOptions = updatedOptionsList
        .filter((item) => {
          return (
            item?.toLocaleLowerCase().startsWith(focusDisplayValue.toLocaleLowerCase().trim()) &&
            !visibleTags.includes(item)
          )
        })
        .sort((a, b) => {
          const x1 = a!.toLocaleUpperCase().trim()
          const x2 = b!.toLocaleUpperCase().trim()
          if (x1 < x2) {
            return -1
          } else if (x1 > x2) {
            return 1
          } else {
            return 0
          }
        })

      return filteredOptions.length > 0 ? (
        filteredOptions.map((item) => (
          <Column key={item}>
            <Button appearance="subtle" className={styles.selectButton} onClick={() => onTagsChange(item)} title={item}>
              {item}
            </Button>
          </Column>
        ))
      ) : (
        <Text>Tag already exists.</Text>
      )
    }

    const targetFinalOptions = filterOptions(filterOptionsSnapshot.data ?? [])

    //close the dropdown when the targetFinalOptions is null
    useEffect(() => {
      if (targetFinalOptions !== null) {
        setOpenSelect(true)
      } else {
        setOpenSelect(false)
      }
    }, [openSelect, targetFinalOptions])

    return (
      <Field label="Tags:" className={className} orientation={orientation} style={style} {...(fieldProps ?? {})}>
        {filterOptionsSnapshot.status === 'waiting' ? (
          <Skeleton>
            <Column>
              <SkeletonItem size={16} />
              <Spacer height={4} />
              <SkeletonItem size={16} />
            </Column>
          </Skeleton>
        ) : (
          <Row style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', minWidth: '150px' }}>
            {defaultValueText === 'Mixed' && (
              <Tag
                dismissible
                value={'Mixed'}
                key={'Mixed'}
                tabIndex={-1}
                className={styles.tagStyle}
                dismissIcon={null}
              >
                <Text className={styles.textStyle}>{'Mixed tags'}</Text>
              </Tag>
            )}
            {visibleTags.map((tag, index) => (
              <Tag
                dismissible
                value={tag}
                key={tag}
                tabIndex={index}
                className={styles.tagStyle}
                dismissIcon={{
                  as: 'span',
                  children: <Dismiss16Filled className={styles.dismissButton} onClick={(e) => onRemoveTag(e, tag)} />,
                }}
              >
                <Text className={styles.textStyle} title={tag}>
                  {tag}
                </Text>
              </Tag>
            ))}
            <CustomTagsPopover
              optionContent={targetFinalOptions}
              inputValue={focusDisplayValue}
              setInputValue={setFocusDisplayValue}
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
              showInput={true}
              onKeyDown={onTagsChange}
            />
          </Row>
        )}
      </Field>
    )
  },
)

CustomTags.displayName = 'CustomTags'
