import { TeamId, UN_TRIAGED_TEAM_IDS, INewTicketData, UN_TRIAGED_TEAM_NAMES } from '@copilot-dash/domain'

import { isNil } from 'lodash'
import { z } from 'zod'

export const summarySatisfyPost = z.object({
  workItemId: z.string(),
  ticketId: z.string(),
  status: z.enum(['New', 'Active', 'Resolved', 'Closed', '']),
  priority: z.number(),
  areaPath: z.string().optional(),
  teamId: z.string(),
  assignTo: z.string().optional(),
  issueList: z.array(
    z.object({
      issueId: z.string(),
      title: z.string(),
      vsoAccount: z.string(),
      visible: z.boolean(),
      rootCauseStatus: z.string(),
    }),
  ),
  customTags: z.array(z.string()).optional(),
  reasoning: z.string(),
  closedComment: z.string(),
})

export const EMPTY_ROOT_CAUSE = ''
function getRootCauseSelectOptionIds(
  rootCauseList:
    | Array<{
        readonly issueId: string
        readonly rootCauseTitle?: string
      }>
    | null
    | undefined,
) {
  if (isNil(rootCauseList)) return [EMPTY_ROOT_CAUSE]
  if (rootCauseList.length === 0) return [EMPTY_ROOT_CAUSE]
  return rootCauseList.map((item) => item.issueId)
}

export const TICKET_STEP_STAGES = ['Untriaged', 'Team Assigned', 'Root Caused', 'Closed'] as const

export function getTicketStepStageByFields(
  status: (typeof COPILOT_DASH_STATE_OPTIONS)[number] | undefined,
  rootCauseList: unknown[] | undefined,
  teamId: TeamId | undefined,
): (typeof TICKET_STEP_STAGES)[number] {
  if (status === 'Active') {
    if (!teamId || (teamId && UN_TRIAGED_TEAM_IDS.includes(Number(teamId)))) return 'Untriaged'
    if (rootCauseList?.length) {
      return 'Root Caused'
    } else {
      return 'Team Assigned'
    }
  } else {
    return 'Closed'
  }
}

export function mapTicketInfoToTicketActionFormValue(
  ticketInfo: Pick<
    INewTicketData,
    'status' | 'reasoning' | 'closedComment' | 'priority' | 'rootCauseList' | 'assignTo' | 'customTags' | 'teamId'
  >,
  ticketCustomTags?: string[],
) {
  return {
    state: mapADOStateToCopilotDashState(ticketInfo.status, ticketInfo.reasoning),
    noActionableReason: ticketInfo.closedComment || 'N/A',
    priority: ticketInfo.priority,
    area: ticketInfo.teamId,
    rootCauseIDs: getRootCauseSelectOptionIds(ticketInfo.rootCauseList),
    assignTo: ticketInfo.assignTo,
    customTags: ticketCustomTags,
  }
}

export type ElementType<T extends ReadonlyArray<unknown>> = T[number]

const ADO_BUG_REASON_OPTIONS = {
  Resolved: [
    'As Designed',
    'Cannot Reproduce',
    'Copied to Backlog',
    'Deferred',
    'Duplicate',
    'Fixed',
    'Obsolete',
    'Will not Fix',
  ] as const,
  Closed: [
    'As Designed',
    'Cannot Reproduce',
    'Copied to Backlog',
    'Deferred',
    'Duplicate',
    'Fixed and verified',
    'Obsolete',
  ] as const,
} as const
export const PRIORITY_OPTIONS = [0, 1, 2, 3, 4] as const
const ADO_STATE_OPTIONS = ['New', 'Active', 'Resolved', 'Closed'] as const
export const COPILOT_DASH_STATE_OPTIONS = [
  'Active',
  'Closed - Not Actionable',
  'Closed - Fixed',
  'Closed - By Design',
] as const

export function mapADOStateToCopilotDashState(
  adoState: ElementType<typeof ADO_STATE_OPTIONS> | '' | undefined,
  reason?: string,
): ElementType<typeof COPILOT_DASH_STATE_OPTIONS> {
  switch (adoState) {
    case undefined:
    case '':
    case ADO_STATE_OPTIONS[0]:
    case ADO_STATE_OPTIONS[1]:
      return COPILOT_DASH_STATE_OPTIONS[0]
    case ADO_STATE_OPTIONS[2]:
      return COPILOT_DASH_STATE_OPTIONS[2]
    case ADO_STATE_OPTIONS[3]: {
      if (reason === 'Fixed and verified') {
        return COPILOT_DASH_STATE_OPTIONS[2]
      } else if (reason === 'As Designed') {
        return COPILOT_DASH_STATE_OPTIONS[3]
      }
      return COPILOT_DASH_STATE_OPTIONS[1]
    }
  }
}

export function mapCopilotDashStateToADOState(
  copilotDashState: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>,
  team: string,
): ElementType<typeof ADO_STATE_OPTIONS> {
  switch (copilotDashState) {
    case COPILOT_DASH_STATE_OPTIONS[0]:
      if (UN_TRIAGED_TEAM_NAMES.includes(team)) {
        return ADO_STATE_OPTIONS[0]
      } else {
        return ADO_STATE_OPTIONS[1]
      }
    case COPILOT_DASH_STATE_OPTIONS[1]:
    case COPILOT_DASH_STATE_OPTIONS[2]:
    case COPILOT_DASH_STATE_OPTIONS[3]:
      return ADO_STATE_OPTIONS[3]
    default:
      return ADO_STATE_OPTIONS[0]
  }
}

export function mapCopilotDashStateToADOReason(
  copilotDashState: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>,
): ElementType<(typeof ADO_BUG_REASON_OPTIONS)[keyof typeof ADO_BUG_REASON_OPTIONS]> | '' {
  switch (copilotDashState) {
    case COPILOT_DASH_STATE_OPTIONS[1]:
      return 'Obsolete'
    case COPILOT_DASH_STATE_OPTIONS[2]:
      return 'Fixed and verified'
    case COPILOT_DASH_STATE_OPTIONS[3]:
      return 'As Designed'
    default:
      return ''
  }
}
