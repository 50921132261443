import { Text, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo, useCallback } from 'react'
import { Row, Column } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { IActivityHistory, TicketIssueData } from '@copilot-dash/domain'
import { getRootCauseDifferentValue } from '../config'

interface IProps {
  readonly activity?: IActivityHistory
}

export const RootCauseFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  const isChange = useMemo(() => {
    if (!activity?.fieldDiffs || !activity.fieldDiffs['issueList']) {
      return false
    }

    const { added, deleted } = getRootCauseDifferentValue(
      activity.fieldDiffs['issueList'].newValue,
      activity.fieldDiffs['issueList'].oldValue,
    )

    return added.length > 0 || deleted.length > 0
  }, [activity])

  const renderValue = useCallback(
    (value: { added: TicketIssueData[]; deleted: TicketIssueData[] }) => {
      const { added, deleted } = value
      return (
        <>
          {added.length > 0 && (
            <>
              {added.map((issue, idx) => (
                <Text key={idx} title={issue.RootCauseTitle} className={mergeClasses(styles.tag, styles.customTag)}>
                  {issue.RootCauseTitle}
                </Text>
              ))}
            </>
          )}
          {deleted.length > 0 && (
            <>
              {deleted.map((issue, idx) => (
                <Text
                  key={idx}
                  title={issue.RootCauseTitle}
                  className={mergeClasses(styles.tag, styles.customTag, styles.deleted)}
                >
                  {issue.RootCauseTitle}
                </Text>
              ))}
            </>
          )}
        </>
      )
    },
    [styles.tag, styles.customTag, styles.deleted],
  )

  const diffContent = useMemo(() => {
    const diff = getRootCauseDifferentValue(
      activity?.fieldDiffs?.['issueList']?.newValue,
      activity?.fieldDiffs?.['issueList']?.oldValue,
    )
    return renderValue(diff)
  }, [renderValue, activity])

  if (!isChange) return null

  return (
    <Row vAlign="start">
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Root Cause:</Text>
      </Column>
      <Column className={styles.rootCauseValue}>{diffContent}</Column>
    </Row>
  )
}
