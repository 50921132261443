import { IDashEnvironment } from '@copilot-dash/core'
import {
  ColumnKey,
  DashSettingEndpoint,
  DefaultCustomizedFilter,
  DefaultFeedbackInsightsTopIssueDetailsColumnIds,
  DefaultFeedbackInsightsVipDSATsColumnIds,
  DefaultSearchScreenAIFColumnIds,
  DefaultSearchScreenNonAIFColumnIds,
  DefaultTeamViewColumnIds,
  SearchFilterFieldNames,
  ThemeMode,
  columnKeySchema,
  searchFilterFieldNamesSchema,
  themeModeSchema,
  AutomationSettingEndpoint,
  DefaultRagResultRableColumnIds,
} from '@copilot-dash/domain'
import { ZodType, z } from 'zod'
import { ISetting } from './common/ISetting'
import { createSetting } from './common/createSetting'

export function createSettings(env: IDashEnvironment) {
  return {
    themeMode: create<ThemeMode>({
      key: 'theme_mode',
      schema: themeModeSchema,
      def: 'light',
    }),

    endpointUrl: create<DashSettingEndpoint | null>({
      key: 'endpoint_url',
      schema: z.nativeEnum(DashSettingEndpoint).nullable(),
      def: null,
    }),

    atendpointUrl: create<AutomationSettingEndpoint | null>({
      key: 'atendpoint_url',
      schema: z.nativeEnum(AutomationSettingEndpoint).nullable(),
      def: null,
    }),

    timezone: create<string>({
      key: 'time_zone',
      schema: z.string(),
      def: 'UTC',
    }),

    privacyModeEnabled: create<boolean>({
      key: 'privacyMode_Enabled',
      schema: z.boolean(),
      def: false,
    }),

    searchScreenNonAIFColumns: create<ColumnKey[]>({
      key: 'searchscreen_non_aif_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultSearchScreenNonAIFColumnIds,
    }),

    searchScreenAIFColumns: create<ColumnKey[]>({
      key: 'searchscreen_aif_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultSearchScreenAIFColumnIds,
    }),

    feedbackInsightsVipDSATsColumns: create<ColumnKey[]>({
      key: 'feedback_insights_vip_dsats_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultFeedbackInsightsVipDSATsColumnIds,
    }),

    feedbackInsightsTopIssueDetailsColumns: create<ColumnKey[]>({
      key: 'top_issue_details_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultFeedbackInsightsTopIssueDetailsColumnIds,
    }),

    teamViewColumns: create<ColumnKey[]>({
      key: 'teamview_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultTeamViewColumnIds,
    }),

    teamViewCustomizedFilterItems: create<SearchFilterFieldNames[]>({
      key: 'teamview_customized_filter_items',
      schema: z.array(searchFilterFieldNamesSchema),
      def: DefaultCustomizedFilter,
    }),

    searchScreenCustomizedFilterItems: create<SearchFilterFieldNames[]>({
      key: 'searchscreen_customized_filter_items',
      schema: z.array(searchFilterFieldNamesSchema),
      def: DefaultCustomizedFilter,
    }),

    sharedQueriesTeamId: create({
      key: 'shared_queries_team_id',
      schema: z.record(z.union([z.number(), z.undefined()])),
      def: {},
    }),

    ticketContextDrawerWidth: create<number | undefined>({
      key: 'ticket_context_drawer_width',
      schema: z.number().optional(),
      def: undefined,
    }),

    discussionPanelDrawerWidth: create<number | undefined>({
      key: 'discussion_panel_drawer_width',
      schema: z.number().optional(),
      def: 640,
    }),

    activityHistoryDrawerWidth: create<number | undefined>({
      key: 'activity_history_drawer_width',
      schema: z.number().optional(),
      def: 640,
    }),

    lastSelectedTeamByProductId: create<Record<number, { teamId: number; teamName: string }>>({
      key: 'last_selected_team_by_product_id',
      schema: z.record(z.object({ teamId: z.number(), teamName: z.string() })),
      def: {},
    }),

    ragResultTableColumns: create<ColumnKey[]>({
      key: 'rag_result_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultRagResultRableColumnIds,
    }),

    translateContentToEnglish: create<boolean>({
      key: 'translate_content_to_english',
      schema: z.boolean(),
      def: false,
    }),
  } satisfies Record<string, ISetting<unknown>>

  function create<T>(params: { key: string; schema: ZodType<T>; def: T }): ISetting<T> {
    return createSetting({
      key: params.key,
      schema: params.schema,
      defaultValue: params.def,
    })
  }
}
