import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  editorContainer: {
    flex: '1 0 1',
    width: '100%',
    height: '100%',
    background: tokens.colorNeutralBackground1,
  },
  editorInput: {
    resize: 'none',
    fontSize: '15px',
    caretColor: tokens.colorScrollbarOverlay,
    color: tokens.colorStrokeFocus2,
    tabSize: 1,
    outline: '0',
    padding: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    height: '100%',
  },
  editorPlaceholder: {
    color: tokens.colorNeutralForeground4,
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    top: '10px',
    left: '10px',
    fontSize: '15px',
    userSelect: 'none',
    display: 'inline-block',
    pointerEvents: 'none',
  },
  divider: { padding: '0 10px' },
})
