import { Label } from '@fluentui/react-components'
import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'
import { Column } from '../../../Layout'
import { memo } from 'react'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (selectedOptions: string[]) => void
}
export const HasVerbatimFilterForAIF: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptions: IFilterOption[] = [
    {
      key: 'Yes',
      text: 'Yes',
    },
  ]

  return (
    <Column>
      <Label weight="semibold">{getTagDisplayName('hasVerbatim')}:</Label>
      <CopilotDashCheckBox
        options={filterOptions}
        selectedOptions={selectedValues}
        onChange={onChangeValue}
        multiple={false}
      />
    </Column>
  )
})

HasVerbatimFilterForAIF.displayName = 'HasVerbatimFilterForAIF'
