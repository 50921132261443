import { z } from 'zod'

const apiOcvTicketIssueSchema = z.record(z.unknown()).and(
  z.object({
    Title: z.string().optional(),
  }),
)

const apiOcvTicketAiContextSchema = z.record(z.unknown()).and(
  z.object({
    Prompt: z.string().optional(),
    PromptInEnglish: z.string().optional(),
    ResponseMessage: z.string().optional(),
    ResponseMessageInEnglish: z.string().optional(),
  }),
)

export const apiOcvTicketSchema = z.record(z.unknown()).and(
  z.object({
    CustomerVerbatimOriginal: z.string().optional(),
    CustomerVerbatimTranslated: z.string().optional(),
    OcvIssues: z.array(apiOcvTicketIssueSchema).optional(),
    AiContext: apiOcvTicketAiContextSchema.optional(),
  }),
)
