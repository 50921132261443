import { z } from 'zod'

export interface ApiTeamItemV2 {
  readonly teamId: string
  readonly teamName: string
  readonly isAssignable: boolean
  readonly adoConfiguration?: {
    adoAccount: string
    adoAreaPath: string
  }
  readonly subTeamList: Array<ApiTeamItemV2>
}

export type ApiV2TeamListResponse = Array<ApiTeamItemV2>

const apiTeamItemV2Schema: z.ZodType<ApiTeamItemV2> = z.object({
  teamId: z.string(),
  teamName: z.string(),
  isAssignable: z.boolean(),
  adoConfiguration: z
    .object({
      adoAccount: z.string(),
      adoAreaPath: z.string(),
    })
    .optional(),
  subTeamList: z.lazy(() => z.array(apiTeamItemV2Schema)),
})

export const apiTeamListResponseV2Schema = z.array(apiTeamItemV2Schema)
