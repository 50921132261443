import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketMessageContent } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { raceRequests } from '../../utils/raceRequests'
import { getRawOcvTicket } from '../actions-raw-ticket/getRawOcvTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getTicketSession } from './getTicketSession'

export function getTicketVerbatim(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketMessageContent> {
  return context.getOrFetch<ITicketMessageContent>({
    get: (state) => {
      return state.tickets[ticketId]?.verbatim
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.verbatim = snapshot
    },
    fetch: async () => {
      if (context.enableV2Endpoint) {
        return doFetchFromV2()
      }

      const verbatim = await doFetch()
      if (verbatim) {
        return verbatim
      }

      throw TicketError.create('NoVerbatim', { ticketId })
    },
  })

  async function doFetchFromV2(): Promise<ITicketMessageContent> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    const verbatim = raw.userFeedback.verbatim
    if (verbatim && verbatim.text) {
      return {
        text: verbatim.text,
        textInEnglish: verbatim.textInEnglish,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoVerbatim', { ticketId })
  }

  async function doFetch(): Promise<ITicketMessageContent> {
    return raceRequests({
      primary: fetchFromSession(),
      secondary: fetchFromOCV(),
    })
  }

  async function fetchFromOCV(): Promise<ITicketMessageContent> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise

    const verbatimFromOcvTicket = ocvTicket?.CustomerVerbatimOriginal
    const customerVerbatimTranslated = ocvTicket?.CustomerVerbatimTranslated
    if (verbatimFromOcvTicket) {
      return {
        text: verbatimFromOcvTicket,
        textInEnglish: customerVerbatimTranslated,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoVerbatim', { ticketId })
  }

  async function fetchFromSession(): Promise<ITicketMessageContent> {
    const sessionTicket = await getTicketSession(context, ticketId).promise

    const verbatim = sessionTicket?.verbatim
    if (verbatim) {
      return {
        text: verbatim,
        textInEnglish: undefined,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoVerbatim', { ticketId })
  }
}
