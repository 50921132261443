import { CopilotDashSingleSelectDropdown } from '../../../Filter/CopilotDashSingleSelectDropdown'
import { memo } from 'react'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { DefaultTriggeredSkillList, DefaultTriggeredSkillListCWC, ProductIds } from '@copilot-dash/domain'
import { useTicketFilterStore } from '../../store/store'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const TriggeredSkillFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
  const filterList =
    selectedProductId === ProductIds.M365ChatWebChat ? DefaultTriggeredSkillListCWC : DefaultTriggeredSkillList
  const filterOptions: IFilterOption[] = filterList.map((item) => ({
    key: item.key,
    text: item.text,
  }))

  return (
    <CopilotDashSingleSelectDropdown
      comboId="TriggeredSearchSkillFilter"
      filterType={getTagDisplayName('triggeredSkill')}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues && selectedValues.length > 0 ? selectedValues[0] : undefined}
      onChangeFilter={onChangeValue}
    />
  )
})

TriggeredSkillFilter.displayName = 'TriggeredSkillFilter'
