import { ISearchScreenPropsQuery } from '../../../screens/search/SearchScreen'
import { ProductNames } from '@copilot-dash/domain'
import { SearchRoute } from './SearchRoute'
import { useCallback } from 'react'

export function useSearchScreenNavigate() {
  return useCallback((query: ISearchScreenPropsQuery) => {
    SearchRoute.navigator.navigate({
      product: query.product || ProductNames.M365Chat,
      agentTypes: query.agentTypes,
      appTypes: query.appTypes,
      applications: query.applications,
      platforms: query.platforms,
      licenses: query.licenses,
      authTypes: query.authTypes,
      channel: query.channel,
      customerTypes: query.customerTypes,
      groundedPrompts: query.groundedPrompts,
      ring: query.ring,
      promptLanguages: query.promptLanguages,
      userId: query.userId,
      range: query.range,
      hasVerbatim: query.hasVerbatim,
      hasUserConsent: query.hasUserConsent,
      invocationSlicers: query?.invocationSlicers,
      invocationType: query?.invocationType,
      triggeredSkill: query?.triggeredSkill,
      hasCitation: query?.hasCitation,
      hasEntityCard: query?.hasEntityCard,
      hitAvalon: query?.hitAvalon,
      isCooked: query.isCooked,
      isApology: query.isApology,
      searchText: query.searchText,
      searchTextPrefix: query.searchTextPrefix,
      tenantIds: query.tenantIds,
      thumbs: query.thumbs,
      priority: query.priority,
      isSTCAChina: query.isSTCAChina,
      isTopi18N: query.isTopi18N,
      responseHeroType: query.responseHeroType,
      responseLinkType: query.responseLinkType,
      semanticSearchType: query.semanticSearchType,
      experienceType: query.experienceType,
      hasConnector: query.hasConnector,
      hasGPTExtension: query.hasGPTExtension,
      hasMessageExtension: query.hasMessageExtension,
      hasCopilotExtensionIds: query.hasCopilotExtensionIds,
      optionsSets: query.optionsSets,
      tab: query.tab,
      issueId: query.issueId,
      issueBatchId: query.issueBatchId,
      errorCode: query.errorCode,
      isGCIntent: query.isGCIntent,
      hasConnectorResult: query.hasConnectorResult,
      dSATStatus: query.dSATStatus,
      customTags: query.customTags,
      sliceIds: query.sliceIds,
      copilotExtensionIds: query.copilotExtensionIds,
      queryId: query.queryId,
      flights: query.flights,
      hasErrorMessages: query.hasErrorMessages,
      meetingScenarios: query.meetingScenarios,

      order: query.order,
      hasScreenshot: query.hasScreenshot,
    })
  }, [])
}
