import { PromiseSnapshot } from '@copilot-dash/core'
import { ITeamData, ProductIds } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getTeams(context: IDashStoreContext, productId: ProductIds): PromiseSnapshot<ITeamData[]> {
  return context.getOrFetch<ITeamData[]>({
    get: (state) => {
      return state.team.teams?.[productId]
    },
    set: (state, snapshot) => {
      const teams = (state.team.teams ??= {})
      teams[productId] = snapshot
    },
    fetch: async () => {
      if (context.enableV2TeamView) {
        const response = await context.api.copilotDash.getTeamListFlatten(productId)
        return response
          .map((team) => ({
            id: team.teamId,
            name: team.teamName,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      } else {
        const response = await context.api.logCollector.getTeamList({ ProductId: Number(productId) })
        return response.teamList
          .map((team) => ({
            id: String(team.teamId),
            name: team.teamName,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
  })
}
