import { useFilterOptions } from '@copilot-dash/store'
import { Spinner } from '@fluentui/react-components'
import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { ProductIds } from '@copilot-dash/domain'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (InvocationTypes: string[]) => void
  selectedProductId: ProductIds
}

export const ErrorCodeFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, selectedProductId }) => {
  const filterOptionsSnapshot = useFilterOptions('Customized tag', 'DataCollectionErrorCode')
  const filterOptions: IFilterOption[] = useMemo(() => {
    let updatedErrorCodes: IFilterOption[] = []

    switch (filterOptionsSnapshot.status) {
      case 'done':
        // Map through the data and update the menu field based on the key prefix
        updatedErrorCodes = filterOptionsSnapshot.data.map((item) => {
          if (item.key.startsWith('ConvErrorCode:')) {
            return { ...item, menu: 'ConvErrorCode' }
          } else if (item.key.startsWith('3SErrorCode:')) {
            return { ...item, menu: '3SErrorCode', hidden: selectedProductId === ProductIds.M365ChatWebChat } //hide 3SErrorCode for M365ChatWebChat
          } else {
            return item
          }
        })
        break
      default:
        updatedErrorCodes = []
    }

    return updatedErrorCodes
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status, selectedProductId])

  return filterOptionsSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <CopilotDashMultiSelectDropdown
      comboId="ErrorCodeFilter"
      filterType={getTagDisplayName('errorCode')}
      infoLabelContent={
        <>
          Refer to{' '}
          <a
            href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/466764/Search-Filters#:~:text=to%20certain%20requests.-,Error%20Code,-%3A%20Error%20defined%20by"
            target="_blank"
            rel="noopener noreferrer"
          >
            wiki
          </a>{' '}
          to understand the definitions of the error code filters.
        </>
      }
      optionsList={filterOptions.filter((item) => !item.hidden)}
      isGroup={true}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
}
