import { Button, Text, Tooltip } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../components/Layout'
import { SearchRegular } from '@fluentui/react-icons'
import { useStyles } from './TeamDRIFilter.style'
import { useState } from 'react'
import { UserPhoto } from '../../../components/User/UserPhoto'
import { UserDisplayName } from '../../../components/User/UserDisplayName'
import { useTeamViewStore } from '../store'
import { PeoplePicker } from '../../../components/PeoplePicker/PeoplePicker'

interface ITeamDRIFilterProps {
  selectedTeamDRIList: string[]
  setSelectedTeamDRIList: (list: string[]) => void
}

export const TeamDRIFilter = ({ selectedTeamDRIList, setSelectedTeamDRIList }: ITeamDRIFilterProps) => {
  const styles = useStyles()

  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const [visible, setVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const cannotAddMore = selectedTeamDRIList.length > 2

  const onChangeUser = (_: string, userEmail: string | undefined) => {
    if (userEmail) {
      setInputValue('')
      const newList = [...new Set([...selectedTeamDRIList, userEmail])]
      if (!teamId) return
      application.store.actions.setTeamDRIList(teamId, newList).then(() => {
        setSelectedTeamDRIList(newList)
      })
    }
  }

  const handleRemove = (val: string) => {
    const newSelectedList = selectedTeamDRIList.filter((item) => item !== val)
    if (!teamId) return
    application.store.actions.setTeamDRIList(teamId, newSelectedList).then(() => {
      setSelectedTeamDRIList(newSelectedList)
    })
  }

  return (
    <Column>
      <Tooltip
        withArrow
        visible={visible && cannotAddMore}
        onVisibleChange={(_ev, data) => setVisible(data.visible)}
        content={<div>Only up to 3 DRIs is allowed. Remove before adding a new one.</div>}
        relationship="label"
      >
        <div>
          <PeoplePicker
            onSelect={(userId, userEmail) => {
              onChangeUser(userId, userEmail)
            }}
            onDeselect={() => {
              onChangeUser('', '')
            }}
            inputValue={inputValue}
            onInputChange={setInputValue}
            disable={cannotAddMore}
            icon={<SearchRegular className={styles.search} />}
            placeholder="Add by name, email..."
          />
        </div>
      </Tooltip>
      <div style={{ marginTop: '12px' }}>
        {selectedTeamDRIList.map((item) => {
          return (
            <Row space="between" key={item} className={styles.selectedListContainer}>
              <Row>
                <UserPhoto userId={item} className={styles.customUserPhoto} />
                <Spacer width={'4px'} />
                <Column>
                  <UserDisplayName
                    userId={item}
                    className={styles.customUserName}
                    isEnableToolTip={false}
                    fallback={item}
                  />
                  <Text className={styles.text}>{item}</Text>
                </Column>
              </Row>
              <Button
                style={{ color: '#242424', fontSize: '12px', fontWeight: 400 }}
                onClick={() => handleRemove(item)}
              >
                Remove
              </Button>
            </Row>
          )
        })}
      </div>
    </Column>
  )
}
