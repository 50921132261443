import { LicenseType, ProductIds, ProductLicense } from '@copilot-dash/domain'
import { compact, includes, sortBy, uniq } from 'lodash'
import { getTagDisplayName } from '../../config/SupportedTags'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { useMemo } from 'react'
import { useProductIdEndpoints } from '@copilot-dash/store'

interface IProps {
  productId: ProductIds
  applications: string[]
  platforms: string[]
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const LicenseFilter: React.FC<IProps> = ({
  productId,
  applications,
  platforms,
  selectedValues,
  onChangeValue,
}) => {
  let productEndpoints = useProductIdEndpoints({ productId }).data

  productEndpoints =
    applications.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(applications, endpoint.application))

  productEndpoints =
    platforms.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(platforms, endpoint.platform))

  const licenseList = useMemo(() => {
    const items = uniq(compact(productEndpoints?.map((endpoint) => endpoint.license)))

    return sortBy(items, (item) => sortLicense(item)).map((license) => ({
      key: license,
      text: license,
    }))
  }, [productEndpoints])

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-licenseFilter'}
        filterType={getTagDisplayName('licenses')}
        isGroup={true}
        optionsList={licenseList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
      />
    </>
  )
}

function sortLicense(item: LicenseType): number {
  return ProductLicense.indexOf(item)
}
