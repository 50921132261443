import { ApiCreateRootCauseRequest, ApiUpdateRootCauseRequest } from '@copilot-dash/api'
import { ICreateRootCause, IUpdateRootCause } from '@copilot-dash/domain'

export class RootCauseDataConverter {
  static postDataToApi(data: ICreateRootCause): ApiCreateRootCauseRequest {
    return {
      Title: data.title,
      Description: data.description,
      Priority: data.priority,
      TeamId: Number(data.teamId),
      VsoAccount: data.vsoAccount,
      ProductId: Number(data.productId),
      RootCauseStatus: data.rootCauseStatus,
      Owner: data.owner,
      OriginalCommittedDate: data.originalCommittedDate,
    }
  }

  static updateDataToApi(data: IUpdateRootCause): ApiUpdateRootCauseRequest {
    return {
      Title: data.title,
      Description: data.description,
      Priority: data.priority,
      RootCauseStatus: data.rootCauseStatus,
      TeamId: Number(data.teamId),
      VsoAccount: data.vsoAccount,
      WorkItemId: data.workItemId,
      Owner: data.owner,
      OriginalCommittedDate: data.originalCommittedDate,
      ProductId: Number(data.productId),
    }
  }
}
