import { useProductIdChannels } from '@copilot-dash/store'
import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { ProductIds } from '@copilot-dash/domain'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  productId: ProductIds | undefined
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const ChannelFilter: React.FC<IProps> = ({ productId, selectedValues, onChangeValue }) => {
  const productChannels = useProductIdChannels({ productId })
  const channelLists: IFilterOption[] = useMemo(() => {
    return (
      productChannels.data?.map((channel) => ({
        key: channel.name,
        text: channel.title,
      })) ?? []
    )
  }, [productChannels.data])

  return (
    <CopilotDashMultiSelectDropdown
      comboId="ChannelFilter"
      filterType={getTagDisplayName('channel')}
      isGroup={false}
      optionsList={channelLists}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
}
