import { PromiseSnapshot } from '@copilot-dash/core'
import {
  IActivityHistory,
  FieldDiff,
  TicketIssueData,
  ActivityHistoryRootCauseContext,
  ticketIssueDataSchema,
} from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { ActivityHistoryConverter } from './ActivityHistoryConverter'
import { isEmpty, isNil, xorBy } from 'lodash'
import { z } from 'zod'

export function getTicketActivityHistory(
  context: IDashStoreContext,
  ticketId: string,
  forceRequest?: boolean,
): PromiseSnapshot<IActivityHistory[]> {
  return context.getOrFetch({
    get: (state) => {
      return forceRequest ? undefined : state.tickets[ticketId]?.activityHistory
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.activityHistory = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getActivityHistory(ticketId)
      return response.activityHistories
        .map(ActivityHistoryConverter.getActivityHistories)
        .map(filterFieldDiffs)
        .filter(shouldIncludeActivity)
        .sort((a, b) => b.revision - a.revision)
    },
  })
}

function isValidFieldDiff(diff: FieldDiff): boolean {
  const isValidValue = (
    value?: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
  ): boolean => {
    if (Array.isArray(value)) {
      return value.length > 0
    }
    if (typeof value === 'string') {
      return value.trim() !== ''
    }
    return !isNil(value)
  }

  return isValidValue(diff.oldValue) || isValidValue(diff.newValue)
}

function shouldIncludeActivity(activity: IActivityHistory): boolean {
  if (activity.activity === 'FeedbackCreated' || activity.activity === 'FeedbackCooked') return true
  return activity.activity !== 'Unset' && !isEmpty(activity.fieldDiffs)
}

function isTicketIssueDataArray(value: unknown): value is TicketIssueData[] {
  return z.array(ticketIssueDataSchema).safeParse(value).success
}

function filterFieldDiffs(activity: IActivityHistory): IActivityHistory {
  const fieldDiffs = activity.fieldDiffs ?? {}
  const filteredFieldDiffs = Object.keys(fieldDiffs)
    .filter((key) => key !== 'closedComment' && key !== 'reasoning')
    .filter((key) => {
      const diff = fieldDiffs[key]
      if (diff === undefined || !isValidFieldDiff(diff)) {
        return false
      }
      if (
        key === 'issueList' &&
        Array.isArray(diff.oldValue) &&
        Array.isArray(diff.newValue) &&
        isTicketIssueDataArray(diff.oldValue) &&
        isTicketIssueDataArray(diff.newValue)
      ) {
        if (xorBy(diff.oldValue, diff.newValue, 'IssueId').length === 0) {
          return false
        }
      }
      return true
    })
    .reduce(
      (obj: Record<string, FieldDiff>, key) => {
        if (fieldDiffs[key] !== undefined) {
          obj[key] = fieldDiffs[key] as FieldDiff
        }
        return obj
      },
      {} as Record<string, FieldDiff>,
    )
  return { ...activity, fieldDiffs: filteredFieldDiffs }
}
