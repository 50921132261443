import { ApiCopilotTicketDetailContext } from '@copilot-dash/api'
import { ITicketData, TicketEmotionType, getProductIdByEndpoint } from '@copilot-dash/domain'
import { isAssignedTo3SDashTorus } from '../../../utils/is3SDashTorus'

export function convertTicketDataFromApiContext(api: ApiCopilotTicketDetailContext): ITicketData {
  const ticketId = api.externalTicketId
  return {
    ...api,
    emotionType: api.thumbFeedback === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
    productId: getProductIdByEndpoint(api.clientName) ? getProductIdByEndpoint(api.clientName) : undefined,
    teamId: api.teamId ? String(api.teamId) : undefined,
    ticketId,
    assignTo: isAssignedTo3SDashTorus(api.assignTo) ? undefined : api.assignTo, // `3SDashTorus`, it's a robot, should be ignored
  }
}
