import { INewTicketData, RootCauseActionType, SearchTextPrefixType } from '@copilot-dash/domain'
import { Button, Text } from '@fluentui/react-components'
import { ArrowDownRegular, ArrowSortRegular, ArrowUpRegular } from '@fluentui/react-icons'
import { compact } from 'lodash'
import * as React from 'react'
import { Row, Spacer } from '../../../components/Layout'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { useTeamViewStore } from '../store'
import { ClusteringTickets } from './ClusteringTickets'
import { QuickSearch } from './QuickSearch'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { useStyles } from './UnRootCauseTickets.styles'
import { IAGGridTableRef } from '../../../components/AGGridTable/AGGridTable'
import { ColDef } from 'ag-grid-community'

interface IUnRootCauseTicketsProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
}

export const UnRootCauseTickets: React.FC<IUnRootCauseTicketsProps> = React.memo(({ onRowClick, clickedTicketKey }) => {
  const tablesRef = React.useRef<{
    [key: string]: IAGGridTableRef | null
  }>({})
  const recommendedRootCausesList = useTeamViewStore(
    (state) => state.rootCauses.teamRootCauses.data?.recommendedRootCausesList ?? [],
  )

  const unRootCausedTicketIds = useTeamViewStore((state) => state.tickets.unRootCausedTicketIds)

  const clusteringIssueCount = recommendedRootCausesList
    .map((item) => unRootCausedTicketIds[item.issueId]?.data?.ticketCount)
    .filter((count) => count && count > 0).length
  const orderBy = useTeamViewStore((state) => state.orderBy)
  const clusteringList = React.useMemo(() => {
    const defaultClusteringList = [...recommendedRootCausesList, { issueId: '-1', rootCauseTitle: 'Others' }]
    let sortClusteringList = defaultClusteringList.map((item) => {
      const recommendationCount = unRootCausedTicketIds[item.issueId]?.data?.ticketCount
      return { ...item, recommendationCount: recommendationCount ?? 0 }
    })

    if (orderBy) {
      sortClusteringList = sortClusteringList
        .filter((i) => i.issueId !== '-1')
        .sort((a, b) =>
          orderBy === 'Positive'
            ? a.recommendationCount - b.recommendationCount
            : b.recommendationCount - a.recommendationCount,
        )
      sortClusteringList.push({
        issueId: '-1',
        rootCauseTitle: 'Others',
        recommendationCount: unRootCausedTicketIds['-1']?.data?.ticketCount ?? 0,
      })
    }
    return sortClusteringList
  }, [recommendedRootCausesList, orderBy, unRootCausedTicketIds])

  const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
  const styles = useStyles()
  const columnSetting = app.settings.teamViewColumns.use()
  const setColumnSetting = app.settings.teamViewColumns.set

  const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
  const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)

  const tableColumns = React.useMemo(() => {
    const defaultColumns = AllSearchTableColumns(searchTextPrefix || SearchTextPrefixType.All, searchText)
    const newSelectedColumns: ColDef<INewTicketData>[] = compact(
      columnSetting.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
    )

    return newSelectedColumns
  }, [columnSetting, searchTextPrefix, searchText])

  const headerText = 'Un-Root Caused DSATs'
  const unRootCauseTicketsCount = useTeamViewStore((state) => state.tickets.computed.unRootCauseTicketsCount)

  const currentSelectedGroupKeyRef = React.useRef('')
  const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined, groupKey: string) => {
    if (groupKey !== currentSelectedGroupKeyRef.current) {
      tablesRef.current[currentSelectedGroupKeyRef.current]?.clearSelection()
    }
    currentSelectedGroupKeyRef.current = groupKey
    useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
    useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
    if ((selectedItems ?? [])?.length > 0) {
      useTeamViewStore.getState().toggleBatchUpdatePanel(true)
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
      const firstTicket = selectedItems?.[0]
      if (firstTicket?.ticketId) {
        application.store.actions.getTicketRootCause(firstTicket.ticketId).then((resp) => {
          const suggestion = resp.items.filter(
            (item) =>
              item.userAction === RootCauseActionType.UserNoAction ||
              item.userAction === RootCauseActionType.DirectLinked,
          )[0]
          if (suggestion) {
            useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(suggestion)
          }
        })
      }
    } else {
      useTeamViewStore.getState().toggleBatchUpdatePanel(false)
    }
  }, [])

  const handleClick = () => {
    switch (orderBy) {
      case 'Reverse':
        useTeamViewStore.getState().setOrderBy('Positive')
        break
      case 'Positive':
        useTeamViewStore.getState().setOrderBy('Reverse')
        break
      default:
        useTeamViewStore.getState().setOrderBy('Reverse')
        break
    }
  }

  return (
    <>
      <Row className={styles.header}>
        {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
        <Text size={500} weight="semibold">
          {headerText}
          {typeof unRootCauseTicketsCount === 'number' && `(${unRootCauseTicketsCount})`}
        </Text>
        <Spacer width={8} />
        {typeof unRootCauseTicketsCount === 'number' && unRootCauseTicketsCount > 0 && clusteringIssueCount > 1 && (
          <Button
            icon={orderBy ? orderBy === 'Positive' ? <ArrowUpRegular /> : <ArrowDownRegular /> : <ArrowSortRegular />}
            aria-label="Sort Root Cause Recommendation by counts"
            onClick={handleClick}
          >
            Sort
          </Button>
        )}
      </Row>
      <QuickSearch />
      {clusteringList.map((rootCause) => (
        <ClusteringTickets
          key={rootCause.issueId}
          rootCause={{
            issueId: rootCause.issueId,
            title: rootCause.rootCauseTitle,
            vsoAccount: '',
            rootCauseStatus: 'Active',
          }}
          columns={tableColumns}
          clickedTicketKey={clickedTicketKey}
          onRowClick={onRowClick}
          onSelectionChange={(tickets) => handleSelectionChange(tickets, rootCause.issueId)}
          tableRef={(ref) => {
            tablesRef.current[rootCause.issueId] = ref
          }}
          clusteringListLength={clusteringList.length}
          setColumns={setColumnSetting}
        />
      ))}
    </>
  )
})

UnRootCauseTickets.displayName = 'UnRootCauseTickets'
