import { memo } from 'react'
import { CopilotDashSearchBox } from '../../../Filter/CopilotDashSearchBox'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  value: string | undefined
  onChangeValue: (value?: string) => void
}

export const CopilotExtensionIdsFilter: React.FC<IProps> = memo(({ value, onChangeValue }) => {
  return (
    <CopilotDashSearchBox
      value={value}
      onChangeValue={onChangeValue}
      title={getTagDisplayName('copilotExtensionIds') + ':'}
      placeholder="Search CopilotExtensionIds"
      info="Search Agent AppId (aka. TitleId) or GPTId (aka. packageRelativeAgentId)"
    />
  )
})

CopilotExtensionIdsFilter.displayName = 'CopilotExtensionIdsFilter'
