import { useFilterOptions } from '@copilot-dash/store'
import { Spinner } from '@fluentui/react-components'
import { memo, useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const SemanticSearchTypeFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('Customized tag', 'SemanticSearchType')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])

  return filterOptionsSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <CopilotDashMultiSelectDropdown
      comboId="SemanticSearchTypeFilter"
      filterType={getTagDisplayName('semanticSearchType')}
      optionsList={filterOptions}
      isGroup={false}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
})

SemanticSearchTypeFilter.displayName = 'SemanticSearchTypeFilter'
