import { ITeamViewRouteArgs, teamViewRouteStateSchema } from './types'
import {
  ProductName,
  SearchTextPrefixType,
  TeamViewSubMenuIds,
  TicketAuthType,
  TicketDSATStatusType,
  TicketRingType,
} from '@copilot-dash/domain'
import { TimeRange, relativeTimeUnitSchema } from '@copilot-dash/core'

import { IRouteMapper } from '../../types/IRouteMapper'
import { IRouteParams } from '../../types/IRouteParams'
import { RouteDataReader } from '../../utils/RouteDataReader'

const PATH_PARAM_PRODUCT = 'product'

enum TeamViewRouteParams {
  queryId = 'queryId',
  teamName = 'teamName',
  subMenu = 'subMenu',
  ticketStatus = 'ticketStatus',
  issueId = 'issueId',
  vsoAccount = 'vsoAccount',
  agentTypes = 'agentTypes',
  appTypes = 'appTypes',
  applications = 'applications',
  platforms = 'platforms',
  licenses = 'licenses',
  authTypes = 'authTypes',
  channel = 'channel',
  customerTypes = 'customerTypes',
  ring = 'ring',
  promptLanguages = 'promptLanguages',
  email = 'email',
  userId = 'userId',
  fromDateUtc = 'fromDateUtc',
  toDateUtc = 'toDateUtc',
  lastTime = 'lastTime',
  lastTimeUnit = 'lastTimeUnit',
  hasVerbatim = 'hasVerbatim',
  hasUserConsent = 'hasUserConsent',
  isCooked = 'isCooked',
  isApology = 'isApology',
  searchText = 'searchText',
  tenantIds = 'tenantIds',
  groundedPrompts = 'groundedPrompts',
  thumbs = 'thumbs',
  invocationType = 'invocationType',
  triggeredSkill = 'triggeredSkill',
  hasCitation = 'hasCitation',
  hasEntityCard = 'hasEntityCard',
  hitAvalon = 'hitAvalon',
  priority = 'priority',
  isSTCAChina = 'isSTCAChina',
  isTopi18N = 'isTopi18N',
  responseHeroType = 'responseHeroType',
  responseLinkType = 'responseLinkType',
  semanticSearchType = 'semanticSearchType',
  experienceType = 'experienceType',
  topIssueBatchId = 'topIssueBatchId',
  hasConnector = 'hasConnector',
  hasGPTExtension = 'hasGPTExtension',
  hasMessageExtension = 'hasMessageExtension',
  hasCopilotExtensionIds = 'hasCopExtIds',
  optionsSets = 'optionsSets',
  searchTextPrefix = 'prefix',
  errorCode = 'errorCode',
  isGCIntent = 'isGCIntent',
  hasConnectorResult = 'hasConnectorResult',
  dSATStatus = 'dSATStatus',
  customTags = 'customTags',
  dSATAssignedTo = 'dSATAssignedTo',
  copilotExtensionIds = 'copExtIds',
  sliceIds = 'sliceIds',
  flights = 'flights',
  hasErrorMessages = 'hasErrorMessages',
  meetingScenarios = 'meetingScenarios',
  hasScreenshot = 'hasScreenshot',
}

export const TeamViewRouteMapper: IRouteMapper<ITeamViewRouteArgs> = {
  mapToParams(args: ITeamViewRouteArgs): IRouteParams {
    return {
      pathParams: {
        product: args.product,
      },
      queryParams: {
        [TeamViewRouteParams.queryId]: args.queryId,
        [TeamViewRouteParams.teamName]: args?.teamName,
        [TeamViewRouteParams.subMenu]: args?.subMenu,
        [TeamViewRouteParams.topIssueBatchId]: args?.topIssueBatchId,
        [TeamViewRouteParams.ticketStatus]: args?.ticketStatus,
        [TeamViewRouteParams.issueId]: args?.issueId,
        [TeamViewRouteParams.vsoAccount]: args?.vsoAccount,
        [TeamViewRouteParams.applications]: args?.applications,
        [TeamViewRouteParams.platforms]: args?.platforms,
        [TeamViewRouteParams.licenses]: args?.licenses,
        [TeamViewRouteParams.authTypes]: args?.authTypes,
        [TeamViewRouteParams.channel]: args?.channel,
        [TeamViewRouteParams.customerTypes]: args?.customerTypes,
        [TeamViewRouteParams.ring]: args?.ring,
        [TeamViewRouteParams.promptLanguages]: args?.promptLanguages,
        [TeamViewRouteParams.userId]: args?.userId,
        [TeamViewRouteParams.fromDateUtc]: args.range?.type === 'absolute' ? args.range.from : undefined,
        [TeamViewRouteParams.toDateUtc]: args.range?.type === 'absolute' ? args.range.to : undefined,
        [TeamViewRouteParams.lastTime]: args.range?.type === 'relative' ? args.range.value.toString() : undefined,
        [TeamViewRouteParams.lastTimeUnit]: args.range?.type === 'relative' ? args.range.unit.toString() : undefined,
        [TeamViewRouteParams.hasVerbatim]: args?.hasVerbatim?.toString(),
        [TeamViewRouteParams.hasUserConsent]: args?.hasUserConsent?.toString(),
        [TeamViewRouteParams.isCooked]: args?.isCooked,
        [TeamViewRouteParams.isApology]: args?.isApology?.toString(),
        [TeamViewRouteParams.searchText]: args?.searchText,
        [TeamViewRouteParams.searchTextPrefix]: args?.searchTextPrefix,
        [TeamViewRouteParams.tenantIds]: args?.tenantIds,
        [TeamViewRouteParams.groundedPrompts]: args?.groundedPrompts,
        [TeamViewRouteParams.thumbs]: args?.thumbs,
        [TeamViewRouteParams.invocationType]: args?.invocationType,
        [TeamViewRouteParams.triggeredSkill]: args?.triggeredSkill,
        [TeamViewRouteParams.hasCitation]: args?.hasCitation,
        [TeamViewRouteParams.hasEntityCard]: args?.hasEntityCard,
        [TeamViewRouteParams.hitAvalon]: args?.hitAvalon,
        [TeamViewRouteParams.priority]: args?.priority,
        [TeamViewRouteParams.isSTCAChina]: args?.isSTCAChina,
        [TeamViewRouteParams.isTopi18N]: args?.isTopi18N,
        [TeamViewRouteParams.responseHeroType]: args?.responseHeroType,
        [TeamViewRouteParams.responseLinkType]: args?.responseLinkType,
        [TeamViewRouteParams.semanticSearchType]: args?.semanticSearchType,
        [TeamViewRouteParams.experienceType]: args?.experienceType,
        [TeamViewRouteParams.hasConnector]: args?.hasConnector,
        [TeamViewRouteParams.hasGPTExtension]: args?.hasGPTExtension,
        [TeamViewRouteParams.hasMessageExtension]: args?.hasMessageExtension,
        [TeamViewRouteParams.hasCopilotExtensionIds]: args?.hasCopilotExtensionIds,
        [TeamViewRouteParams.optionsSets]: args?.optionsSets,
        [TeamViewRouteParams.errorCode]: args?.errorCode,
        [TeamViewRouteParams.isGCIntent]: args?.isGCIntent,
        [TeamViewRouteParams.hasConnectorResult]: args?.hasConnectorResult,
        [TeamViewRouteParams.dSATStatus]: args?.dSATStatus,
        [TeamViewRouteParams.customTags]: args?.customTags,
        [TeamViewRouteParams.dSATAssignedTo]: args?.dSATAssignedTo,
        [TeamViewRouteParams.copilotExtensionIds]: args?.copilotExtensionIds,
        [TeamViewRouteParams.sliceIds]: args?.sliceIds,
        [TeamViewRouteParams.flights]: args?.flights,
        [TeamViewRouteParams.hasErrorMessages]: args?.hasErrorMessages,
        [TeamViewRouteParams.agentTypes]: args?.agentTypes,
        [TeamViewRouteParams.appTypes]: args?.appTypes,
        [TeamViewRouteParams.meetingScenarios]: args?.meetingScenarios,
        [TeamViewRouteParams.hasScreenshot]: args?.hasScreenshot,
      },
      state: { refreshTicker: args.refreshTicker },
      hash: args.hash,
    }
  },

  mapToArgs(params: IRouteParams): ITeamViewRouteArgs {
    const path = new RouteDataReader(params.pathParams)
    const query = new RouteDataReader(params.queryParams)
    const hash = params.hash

    const getRange = (): TimeRange | undefined => {
      const lastTime = query.getNumber(TeamViewRouteParams.lastTime)
      const lastTimeUnit = query.getString(TeamViewRouteParams.lastTimeUnit)
      if (lastTime && lastTime > 0) {
        return {
          type: 'relative',
          value: lastTime,
          unit: relativeTimeUnitSchema.safeParse(lastTimeUnit).data ?? 'days',
        }
      }

      const from = query.getString(TeamViewRouteParams.fromDateUtc)
      const to = query.getString(TeamViewRouteParams.toDateUtc)
      if (from && to) {
        return {
          type: 'absolute',
          from,
          to,
        }
      }

      return undefined
    }

    return {
      queryId: query.getString(TeamViewRouteParams.queryId),
      teamName: query.getString(TeamViewRouteParams.teamName),
      subMenu: query.getEnum(TeamViewRouteParams.subMenu, TeamViewSubMenuIds) || undefined,
      ticketStatus: query.getString(TeamViewRouteParams.ticketStatus),
      topIssueBatchId: query.getString(TeamViewRouteParams.topIssueBatchId) || undefined,
      range: getRange(),

      issueId: query.getString(TeamViewRouteParams.issueId),
      vsoAccount: query.getString(TeamViewRouteParams.vsoAccount),

      product: path.getString(PATH_PARAM_PRODUCT) as ProductName,
      applications: query.getStringArray(TeamViewRouteParams.applications),
      platforms: query.getStringArray(TeamViewRouteParams.platforms),
      licenses: query.getStringArray(TeamViewRouteParams.licenses),
      authTypes: query.getEnumArray(TeamViewRouteParams.authTypes, TicketAuthType),
      channel: query.getStringArray(TeamViewRouteParams.channel),
      customerTypes: query.getStringArray(TeamViewRouteParams.customerTypes),
      ring: query.getEnumArray(TeamViewRouteParams.ring, TicketRingType),
      promptLanguages: query.getStringArray(TeamViewRouteParams.promptLanguages),
      userId: query.getStringArray(TeamViewRouteParams.userId),
      hasVerbatim: query.getStringArray(TeamViewRouteParams.hasVerbatim) || undefined,
      hasUserConsent: query.getStringArray(TeamViewRouteParams.hasUserConsent) || undefined,
      isCooked: query.getBoolean(TeamViewRouteParams.isCooked),
      isApology: query.getStringArray(TeamViewRouteParams.isApology) || undefined,
      searchText: query.getString(TeamViewRouteParams.searchText),
      tenantIds: query.getStringArray(TeamViewRouteParams.tenantIds),
      groundedPrompts: query.getStringArray(TeamViewRouteParams.groundedPrompts),
      thumbs: query.getStringArray(TeamViewRouteParams.thumbs),
      invocationType: query.getStringArray(TeamViewRouteParams.invocationType) || undefined,
      triggeredSkill: query.getStringArray(TeamViewRouteParams.triggeredSkill) || undefined,
      hasCitation: query.getStringArray(TeamViewRouteParams.hasCitation) || undefined,
      hasEntityCard: query.getStringArray(TeamViewRouteParams.hasEntityCard) || undefined,
      hitAvalon: query.getStringArray(TeamViewRouteParams.hitAvalon) || undefined,
      priority: query.getStringArray(TeamViewRouteParams.priority) || undefined,
      isSTCAChina: query.getStringArray(TeamViewRouteParams.isSTCAChina) || undefined,
      isTopi18N: query.getStringArray(TeamViewRouteParams.isTopi18N) || undefined,
      responseHeroType: query.getStringArray(TeamViewRouteParams.responseHeroType) || undefined,
      responseLinkType: query.getStringArray(TeamViewRouteParams.responseLinkType) || undefined,
      semanticSearchType: query.getStringArray(TeamViewRouteParams.semanticSearchType) || undefined,
      experienceType: query.getStringArray(TeamViewRouteParams.experienceType) || undefined,
      hasConnector: query.getStringArray(TeamViewRouteParams.hasConnector) || undefined,
      hasGPTExtension: query.getStringArray(TeamViewRouteParams.hasGPTExtension) || undefined,
      hasMessageExtension: query.getStringArray(TeamViewRouteParams.hasMessageExtension) || undefined,
      hasCopilotExtensionIds: query.getStringArray(TeamViewRouteParams.hasCopilotExtensionIds) || undefined,
      optionsSets: query.getString(TeamViewRouteParams.optionsSets) || undefined,
      searchTextPrefix: query.getEnum(TeamViewRouteParams.searchTextPrefix, SearchTextPrefixType) || undefined,
      errorCode: query.getStringArray(TeamViewRouteParams.errorCode) || undefined,
      isGCIntent: query.getStringArray(TeamViewRouteParams.isGCIntent) || undefined,
      hasConnectorResult: query.getStringArray(TeamViewRouteParams.hasConnectorResult) || undefined,
      dSATStatus: query.getEnumArray(TeamViewRouteParams.dSATStatus, TicketDSATStatusType) || undefined,
      customTags: query.getStringArray(TeamViewRouteParams.customTags) || undefined,
      dSATAssignedTo: query.getString(TeamViewRouteParams.dSATAssignedTo) || undefined,
      copilotExtensionIds: query.getString(TeamViewRouteParams.copilotExtensionIds) || undefined,
      sliceIds: query.getString(TeamViewRouteParams.sliceIds) || undefined,
      flights: query.getString(TeamViewRouteParams.flights) || undefined,
      hasErrorMessages: query.getStringArray(TeamViewRouteParams.hasErrorMessages) || undefined,
      agentTypes: query.getStringArray(TeamViewRouteParams.agentTypes) || undefined,
      appTypes: query.getStringArray(TeamViewRouteParams.appTypes) || undefined,
      meetingScenarios: query.getStringArray(TeamViewRouteParams.meetingScenarios) || undefined,
      hasScreenshot: query.getBoolean(TeamViewRouteParams.hasScreenshot) || undefined,
      // state to args
      refreshTicker: teamViewRouteStateSchema.safeParse(params.state).data?.refreshTicker,
      // hash
      hash: hash,
    }
  },
}
