import {
  Dialog,
  DialogSurface,
  DialogTrigger,
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  Spinner,
  Field,
  Radio,
  RadioGroup,
  Divider,
} from '@fluentui/react-components'
import { ProductIds, ITeamData, CopilotDashPath, getProductNameById, TeamId } from '@copilot-dash/domain'
import { IFormData, FieldNames, SaveQueryForm } from './SaveQueryForm'
import { FC, memo, useRef, useState, useCallback, useEffect } from 'react'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useToast } from '../../hooks/useToast'
import { SearchRoute, TeamRoute } from '../../router'
import { Spacer } from '../Layout'

export type saveQueryActionType = 'update' | 'create' | 'unset'
interface IProps {
  open: boolean
  onClose: () => void
  onSaved: () => void
  okBtnText?: string
  fields?: FieldNames[]
  type: saveQueryActionType
  canUpdate?: boolean

  title: string
  productId: ProductIds
  teamsOptions: ITeamData[]
  queryDetail: string
  copilotDashPath: CopilotDashPath
  filterPayload: string
  isTeamShared?: boolean
  teamId?: TeamId
  queryId?: string
  disableTeamSharedQueries?: boolean
}

export const SaveQueryDialog: FC<IProps> = memo(
  ({
    open,
    onClose,
    okBtnText = 'Save',
    fields = [FieldNames.Title, FieldNames.TeamId],
    teamsOptions,
    onSaved,
    type,
    canUpdate,

    title,
    queryDetail,
    productId,
    copilotDashPath,
    filterPayload,
    isTeamShared,
    teamId,
    queryId,
    disableTeamSharedQueries,
  }) => {
    const toast = useToast()
    const [isSavable, setIsSavable] = useState(false)
    const [saving, setSaving] = useState(false)
    const [actionType, setActionType] = useState<saveQueryActionType>(type)
    const formDataRef = useRef<IFormData | null>(null)

    const onOk = useCallback(
      async (data: IFormData) => {
        return application.store.actions
          .updateQuery({
            queryId: data.queryId,
            title: data.title,
            productId: data.productId,
            isTeamShared: data.isTeamShared,
            teamId: data.teamId,
            copilotDashQuery: data.copilotDashQuery,
            copilotDashPath: data.copilotDashPath,
            filterPayload: data.filterPayload,
          })
          .then((response) => {
            if (response?.queryId) {
              toast.showSuccess('Query saved successfully.', '', 'top')
              onClose()
              onSaved()
              if (response.copilotDashPath === CopilotDashPath.search) {
                SearchRoute.navigator.navigate({
                  queryId: response.queryId,
                  product: getProductNameById(String(response.productId)) || 'M365Chat',
                })
              } else {
                TeamRoute.navigator.navigate({
                  queryId: response.queryId,
                  product: getProductNameById(String(response.productId)) || 'M365Chat',
                })
              }
            }
          })
          .catch((err) => {
            toast.showError('Failed to save', err.message)
          })
      },
      [toast, onClose, onSaved],
    )
    useEffect(() => {
      setIsSavable(actionType === 'update')
    }, [actionType])
    useEffect(() => {
      setActionType(type)
    }, [type])

    return (
      <Dialog
        open={open}
        onOpenChange={(_, data) => {
          if (!data.open) {
            onClose()
          }
        }}
        modalType="alert"
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle
              action={
                <DialogTrigger action="close">
                  <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
                </DialogTrigger>
              }
            >
              Save the query
            </DialogTitle>

            <DialogContent>
              {canUpdate && type !== 'create' && (
                <Field onClick={(e) => e.stopPropagation()}>
                  Do you want to change the original query or save it as a new query? <Spacer height="4px" />
                  <RadioGroup
                    onChange={(_, data) => setActionType(data.value as saveQueryActionType)}
                    value={actionType}
                  >
                    <Radio value="update" label="Change the original query " />
                    <Radio value="create" label="Save as a new query " />
                  </RadioGroup>
                </Field>
              )}
              {canUpdate && actionType !== 'unset' && (
                <>
                  <Divider /> <Spacer height="4px" />
                </>
              )}
              {actionType === 'update' && (
                <SaveQueryForm
                  fields={fields}
                  afterValidate={(isValid, data) => {
                    setIsSavable(isValid)
                    if (isValid) {
                      formDataRef.current = data
                    }
                  }}
                  defaultValue={{
                    [FieldNames.CopilotDashQuery]: queryDetail,
                    [FieldNames.CopilotDashPath]: copilotDashPath,
                    [FieldNames.FilterPayload]: filterPayload,
                    [FieldNames.ProductId]: productId,
                    [FieldNames.IsTeamShared]: isTeamShared ?? false,
                    [FieldNames.TeamId]: teamId,
                    [FieldNames.QueryId]: queryId,
                    [FieldNames.Title]: title,
                  }}
                  teamsOptions={teamsOptions}
                  disabled={{
                    [FieldNames.IsTeamShared]: true,
                    [FieldNames.TeamId]: true,
                  }}
                  isUpdate={true}
                />
              )}

              {actionType === 'create' && (
                <SaveQueryForm
                  fields={fields}
                  afterValidate={(isValid, data) => {
                    setIsSavable(isValid)
                    if (isValid) {
                      formDataRef.current = data
                    }
                  }}
                  defaultValue={{
                    [FieldNames.CopilotDashQuery]: queryDetail,
                    [FieldNames.CopilotDashPath]: copilotDashPath,
                    [FieldNames.FilterPayload]: filterPayload,
                    [FieldNames.ProductId]: productId,
                    [FieldNames.IsTeamShared]: false,
                    [FieldNames.Title]: '',
                  }}
                  teamsOptions={teamsOptions}
                  disabled={{
                    [FieldNames.IsTeamShared]: disableTeamSharedQueries,
                    [FieldNames.TeamId]: false,
                  }}
                  isUpdate={false}
                />
              )}
            </DialogContent>
            <DialogActions position="end">
              <Button
                disabled={!isSavable || saving}
                appearance="primary"
                onClick={() => {
                  if (formDataRef.current) {
                    setSaving(true)
                    onOk(formDataRef.current).finally(() => setSaving(false))
                  }
                }}
                icon={saving ? <Spinner size="extra-tiny" /> : null}
              >
                {okBtnText}
              </Button>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    )
  },
)

SaveQueryDialog.displayName = 'SaveQueryDialog'
