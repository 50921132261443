import { CopilotDashPath, IQuery, ProductIds, SearchFilterFieldNames } from '@copilot-dash/domain'

import { create } from 'zustand'

export enum HeaderTab {
  Filters = 'Filters',
  SharedQueries = 'SharedQueries',
  MyQueries = 'MyQueries',
}

interface TicketFilterState {
  selectedProductId: ProductIds
  setSelectedProductId: (productId: ProductIds) => void
  supportedTicketFilterItems: SearchFilterFieldNames[]
  setSupportedTicketFilterItems: (items: SearchFilterFieldNames[]) => void
  customizedFilters: SearchFilterFieldNames[]
  setCustomizedFilters: (items: SearchFilterFieldNames[]) => void
  selectedHeaderTab: HeaderTab
  setSelectedHeaderTab: (tab: HeaderTab) => void
  copilotDashPath: CopilotDashPath
  setCopilotDashPath: (path: CopilotDashPath) => void
  queryChanged: boolean
  setQueryChanged: (value: boolean) => void
  currentQuery?: IQuery
  setCurrentQuery: (query?: IQuery) => void
  isAISearchEnable: boolean
  setIsAISearchEnable: (value: boolean) => void
  isInvocationSlicerEnable: boolean
  setIsInvocationSlicerEnable: (value: boolean) => void
  selectedApplications?: string[]
  setSelectedApplications: (items?: string[]) => void
  selectedPlatforms?: string[]
  setSelectedPlatforms: (items?: string[]) => void
  selectedLicenses?: string[]
  setSelectedLicenses: (items?: string[]) => void
}

export const useTicketFilterStore = create<TicketFilterState>((set) => ({
  selectedProductId: ProductIds.M365Chat,
  setSelectedProductId: (productId) => set({ selectedProductId: productId }),
  supportedTicketFilterItems: [],
  setSupportedTicketFilterItems: (items) => set({ supportedTicketFilterItems: items }),
  customizedFilters: [],
  setCustomizedFilters: (items) => set({ customizedFilters: items }),
  selectedHeaderTab: HeaderTab.Filters,
  setSelectedHeaderTab: (tab) => set({ selectedHeaderTab: tab }),
  copilotDashPath: CopilotDashPath.search,
  setCopilotDashPath: (path) => set({ copilotDashPath: path }),
  queryChanged: false,
  setQueryChanged: (value) => set({ queryChanged: value }),
  currentQuery: undefined,
  setCurrentQuery: (query) => set({ currentQuery: query }),
  isAISearchEnable: false,
  setIsAISearchEnable: (value) => set({ isAISearchEnable: value }),
  isInvocationSlicerEnable: false,
  setIsInvocationSlicerEnable: (value) => set({ isInvocationSlicerEnable: value }),
  selectedApplications: [],
  setSelectedApplications: (items) => set({ selectedApplications: items }),
  selectedPlatforms: [],
  setSelectedPlatforms: (items) => set({ selectedPlatforms: items }),
  selectedLicenses: [],
  setSelectedLicenses: (items) => set({ selectedLicenses: items }),
}))
